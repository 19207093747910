import React, { useEffect } from "react";
import LeadCaptureForm from '../../components/LeadCapture/LeadCapture';
import Rating from '../../components/Rating/Rating';
import { Rocket, Shield, Zap } from 'lucide-react'
import './start.scss';
import SEO from '../../components/SEO/SEO';

const Start = () => {
    useEffect(() => {
        document.body.classList.add('start-page');
        return () => {
            document.body.classList.remove('start-page');
        };
    }, []);

    return (
        <>
            <SEO
                title="Get a quote for your next amazing website! – VPixel CO"
                description="Get a free quote for your website project. Professional web development services focused on performance and results."
                ogImage="https://static.vpixel.co/OG-v2-Final-EN.jpg"
                favicon="https://static.vpixel.co/favicon-32x32.png"
                appleFavicon="https://static.vpixel.co/apple-touch-icon.png"
            />
            <section className="leadCapture nostatic">
                <div className="container">
                    <div className="leadCapture__content">
                        <div className="leadCapture__content__info">
                            <Rating />
                            <h1>
                                Start your <span className="color-yellow">amazing website today!</span>
                            </h1>
                            <p>
                                Share your project details with us so we can start planning your next amazing website! We will get back to you with a detailed proposal, timeline and confirmed pricing.
                            </p>

                            <div className="items">
                                <div className="item">
                                    <Rocket className="w-5 h-5 text-purple-500" />
                                    <span>Quick Launch</span>
                                </div>
                                <div className="item">
                                    <Shield className="w-5 h-5 text-green-500" />
                                    <span className="">Secure Build</span>
                                </div>
                                <div className="item">
                                    <Zap className="w-5 h-5 text-yellow-500" />
                                    <span className="">High Performance</span>
                                </div>
                            </div>

                            <ul className="grid gap-2 py-4">
                                <li className="flex items-center">
                                    <svg
                                        className=""
                                        fill="none"
                                        stroke="currentColor"
                                        viewBox="0 0 24 24"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7"></path>
                                    </svg>
                                    Optimized for conversions
                                </li>
                                <li className="flex items-center">
                                    <svg
                                        className=""
                                        fill="none"
                                        stroke="currentColor"
                                        viewBox="0 0 24 24"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7"></path>
                                    </svg>
                                    Mobile-first responsive design
                                </li>
                                <li className="flex items-center">
                                    <svg
                                        className=""
                                        fill="none"
                                        stroke="currentColor"
                                        viewBox="0 0 24 24"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7"></path>
                                    </svg>
                                    SEO-friendly architecture
                                </li>
                                <li className="flex items-center">
                                    <svg
                                        className=""
                                        fill="none"
                                        stroke="currentColor"
                                        viewBox="0 0 24 24"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7"></path>
                                    </svg>
                                    Easy to edit and manage
                                </li>
                            </ul>

                            <p>
                                Lightning-fast, secure, and conversion-focused websites. From concept to launch in record time!
                            </p>


                        </div>
                        <LeadCaptureForm />
                    </div>

                </div>

            </section>




        </>
    );
};

export default Start;
