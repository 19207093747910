export const slate = [
    {
        companyName: "GMP Ingenieros",
        width: 204,
        height: 100,
        logoUrl: "/images/logos/8.webp",
    },
    {
        companyName: "Iglesia Generación de Fe",
        width: 204,
        height: 100,
        logoUrl: "/images/logos/11.webp",
    },
    {
        companyName: "FOCUS",
        width: 204,
        height: 100,
        logoUrl: "/images/logos/7.webp",
    },
    {
        companyName: "Nolita Inmobiliaria",
        width: 204,
        height: 100,
        logoUrl: "/images/logos/34.webp",
    },
    {
        companyName: "Intelitech",
        width: 204,
        height: 100,
        logoUrl: "/images/logos/32.webp",
    },
    {
        companyName: "Tralusso Luxuty Rentals",
        width: 204,
        height: 100,
        logoUrl: "/images/logos/22.webp",
    },
    {
        companyName: "Resérvate",
        width: 204,
        height: 100,
        logoUrl: "/images/logos/17.webp",
    },
    {
        companyName: "Get Mentored Today!",
        width: 204,
        height: 100,
        logoUrl: "/images/logos/29.webp",
    },
    {
        companyName: "Experiencia VINTO",
        width: 204,
        height: 100,
        logoUrl: "/images/logos/33.webp",
    },
    {
        companyName: "Gestiones Integrales del Patrimonio",
        width: 204,
        height: 100,
        logoUrl: "/images/logos/9.webp",
    },
    {
        companyName: "Proyecting AM & CIA LTDA",
        width: 204,
        height: 100,
        logoUrl: "/images/logos/15.webp",
    },

    {
        companyName: "Sociedad de Ingenieros y Arquitectos de Bolívar",
        width: 204,
        height: 100,
        logoUrl: "/images/logos/20.webp",
    },
    {
        companyName: "ROTOFIBRA",
        width: 204,
        height: 100,
        logoUrl: "/images/logos/18.webp",
    },
    {
        companyName: "New Level IT",
        width: 204,
        height: 100,
        logoUrl: "/images/logos/13.webp",
    },
    {
        companyName: "Saber Ambiental",
        width: 204,
        height: 100,
        logoUrl: "/images/logos/19.webp",
    },
    {
        companyName: "Rubén Jaimes - Financiero & Contador",
        width: 204,
        height: 100,
        logoUrl: "/images/logos/23.webp",
    },
    {
        companyName: "Meridian Navigator",
        width: 204,
        height: 100,
        logoUrl: "/images/logos/10.webp",
    },
    {
        companyName: "Tutores TOGO!",
        width: 204,
        height: 100,
        logoUrl: "/images/logos/31.webp",
    },
    {
        companyName: "PMEYAS",
        width: 204,
        height: 100,
        logoUrl: "/images/logos/35.webp",
    },
    {
        companyName: "Ríos de Vida Iglesia Cristiana",
        width: 204,
        height: 100,
        logoUrl: "/images/logos/16.webp",
    },
    {
        companyName: "TareasToGo!",
        width: 204,
        height: 100,
        logoUrl: "/images/logos/21.webp",
    },
    {
        companyName: "ASENAU Academia Marítima",
        width: 204,
        height: 100,
        logoUrl: "/images/logos/6.webp",
    },

    {
        companyName: "MG Quantum Services",
        width: 204,
        height: 100,
        logoUrl: "/images/logos/26.webp",
    },
    {
        companyName: "Ambar Rosa",
        width: 204,
        height: 100,
        logoUrl: "/images/logos/4.webp",
    },

    {
        companyName: "First Class Experience Barranquilla",
        width: 204,
        height: 100,
        logoUrl: "/images/logos/24.webp",
    },

    {
        companyName: "Alejandra Ramírez Coach",
        width: 204,
        height: 100,
        logoUrl: "/images/logos/2.webp",
    },
    {
        companyName: "Replacement Windows Direct",
        width: 204,
        height: 100,
        logoUrl: "/images/logos/28.webp",
    },
];
