import React from 'react';

import { FileEdit, Paintbrush, Globe, Shield, Clock, Zap, TrendingUp, BarChart, MessageSquare, Palette, Edit, Repeat, PhoneCall, RefreshCw, Code, Smartphone, Files, ShoppingBag, Tags, CreditCard, HeartHandshake, Server, LineChart } from 'lucide-react';
import constants from '../../constants.json';
const Pricing = () => {
    return (
        <>
            <section id='pricing' className="pricingWeb">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <div className="title">
                                <span className="subtitle">
                                    Pricing – <span className='color-yellow' style={{ fontWeight: 'bold' }}>Now booking for Q1 2025!</span>
                                </span>
                                <h2 className="title-gradient">
                                    Get a premium website for <span className="color-yellow">a fraction of the cost</span> you'd pay elsewhere
                                </h2>
                                <p className="lead">
                                    Do not spend from <span className="color-yellow">$5,000 to $15,000</span> on a website while you can get a premium website for a fraction of the cost thanks to our strategic location in Colombia.
                                </p>


                                <div className="secured-payment">
                                    <table border="0" cellpadding="10" cellspacing="0">
                                        <tr>
                                            <td style={{ padding: '0' }}>
                                                <a href="https://www.paypal.com/webapps/mpp/paypal-popup" title="How PayPal Works" onclick="javascript:window.open('https://www.paypal.com/webapps/mpp/paypal-popup','WIPaypal','toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=yes, resizable=yes, width=1060, height=700'); return false;">
                                                    <img src="https://www.paypalobjects.com/webstatic/mktg/logo/bdg_payments_by_pp_2line.png" border="0" alt="Payments by PayPal" />
                                                </a>
                                                <div style={{ textAlign: 'center' }}>
                                                    <a href="https://www.paypal.com/co/webapps/mpp/what-is-paypal?locale.x=en_CO">
                                                        <font size="2" face="Arial" color="#0079CD">How PayPal Works</font>
                                                    </a>
                                                </div>
                                            </td>
                                        </tr>
                                    </table>
                                    <span>
                                        All payments are securely processed through PayPal, a trusted global payment platform with bank-level encryption and buyer protection. Contact us if you have any questions about payment options.
                                    </span>
                                </div>


                            </div>
                        </div>
                    </div>
                </div>
                <div className="container container-large">
                    <div className="row">
                        <div className="col-12">

                            <div className="pricing-items">
                                <div className="pricing-item product">

                                    <div className="pricing-item__headline">
                                        <span className='name'>Landing Pages &amp; Small Websites</span>
                                        <div className='d-flex' style={{ gap: '10px' }}>
                                            <span>One off</span>
                                            <span className='available'>Two spots available</span>
                                        </div>
                                    </div>
                                    <div className="pricing-item__price">
                                        <span className='title-gradient'>
                                            <span className='starting'>Starting at</span>
                                            $399
                                        </span>
                                        <span>USD</span>
                                    </div>
                                    <div className="pricing-item__description">
                                        <p className='small'>
                                            Launch an eye-catching landing pages or small websites in days, not months 🚀
                                        </p>
                                        <p>
                                            Perfect for fast and conversion-focused websites that need to be launched in days, not months.
                                        </p>
                                    </div>
                                    <div className="pricing-item__action">
                                        <a href="/start?type=landing" className="button">Start your website now! <span className="arrow"><span className="fa fa-arrow-right"></span></span></a>
                                    </div>
                                    <div className="pricing-item__features">
                                        <p>Features:</p>
                                        <ul>
                                            <li className='white'><Palette className="w-4 h-4" /> Custom, High-end Design</li>
                                            <li className='white'><Edit className="w-4 h-4" /> Free Professional Copywriting</li>
                                            <li><Repeat className="w-4 h-4" /> 4 Hero iterations</li>
                                            <li><PhoneCall className="w-4 h-4" /> Kick-off strategy call</li>
                                            <li><RefreshCw className="w-4 h-4" /> 2x rounds of revisions</li>
                                            <li><Clock className="w-4 h-4" /> Updates every 48 hours</li>
                                            <li className='white'><Code className="w-4 h-4" /> React or WordPress Development</li>
                                            <li><Smartphone className="w-4 h-4" /> Mobile, Tablet & Desktop layouts</li>
                                        </ul>
                                    </div>

                                </div>
                                <div className="pricing-item product">
                                    <div className="pricing-item__headline">
                                        <span className='name'>WordPress &amp; Marketing Sites</span>
                                        <div className='d-flex' style={{ gap: '10px' }}>
                                            <span>One off</span>
                                            <span className='available'>Three spots available</span>
                                        </div>
                                    </div>
                                    <div className="pricing-item__price">
                                        <span className='title-gradient'>
                                            <span className='starting'>Starting at</span>
                                            $899
                                        </span>
                                        <span>USD</span>
                                    </div>
                                    <div className="pricing-item__description">
                                        <p className='small'>
                                            If you want a fully custom site, top-tier design, and a team that will build it, this is the best option.
                                        </p>
                                        <p>
                                            Perfect for businesses that want a fully customized site with a top-tier design, and compelling copywriting.
                                        </p>

                                    </div>
                                    <div className="pricing-item__action">
                                        <a href="/start?type=wordpress" className="button">Start your website now! <span className="arrow"><span className="fa fa-arrow-right"></span></span></a>
                                    </div>
                                    <div className="pricing-item__features">
                                        <p>Features:</p>
                                        <ul>
                                            <li className='white'><Palette className="w-4 h-4" /> Custom, High-end Design</li>
                                            <li className='white'><LineChart className="w-4 h-4" /> Google Analytics & SEO</li>
                                            <li className='white'><Files className="w-4 h-4" /> Unlimited Internal Pages</li>
                                            <li className='white'><Edit className="w-4 h-4" /> Free Professional Copywriting</li>
                                            <li><Repeat className="w-4 h-4" /> 4 Hero iterations</li>
                                            <li><PhoneCall className="w-4 h-4" /> Kick-off strategy call</li>
                                            <li><RefreshCw className="w-4 h-4" /> 4x rounds of revisions</li>
                                            <li><Clock className="w-4 h-4" /> Updates every 36 hours</li>
                                            <li><Code className="w-4 h-4" /> React or WordPress Development</li>
                                            <li><Smartphone className="w-4 h-4" /> Mobile, Tablet & Desktop layouts</li>
                                            <li><HeartHandshake className="w-4 h-4" /> 6-months FREE support</li>
                                            <li className='white'><Server className="w-4 h-4" /> Free 1 year Hosting & Domain</li>
                                        </ul>
                                    </div>

                                </div>
                                <div className="pricing-item product">
                                    <div className="pricing-item__headline">
                                        <span className='name'>Custom Sites &amp; E-commerce</span>
                                        <div className='d-flex' style={{ gap: '10px' }}>
                                            <span>One off</span>
                                            <span className='available'>One spot available</span>
                                        </div>
                                    </div>
                                    <div className="pricing-item__price">
                                        <span className='title-gradient'>
                                            <span className='starting'>Starting at</span>
                                            $1099
                                        </span>
                                        <span>USD</span>
                                    </div>
                                    <div className="pricing-item__description">
                                        <p className='small'>
                                            Sell and scale your business with a fully custom site, catalog, or e-commerce platform.
                                        </p>
                                        <p>
                                            Best option for selling products or services online with a fully custom site, catalog, or e-commerce platform.
                                        </p>

                                    </div>
                                    <div className="pricing-item__action">
                                        <a href="/start?type=ecommerce" className="button">Start your website now! <span className="arrow"><span className="fa fa-arrow-right"></span></span></a>
                                    </div>
                                    <div className="pricing-item__features">
                                        <p>Features:</p>
                                        <ul>

                                            <li className='white'><Palette className="w-4 h-4" /> Custom, High-end Design</li>
                                            <li className='white'><LineChart className="w-4 h-4" /> Google Analytics & SEO</li>
                                            <li className='white'><Files className="w-4 h-4" /> Unlimited Internal Pages</li>
                                            <li className='white'><ShoppingBag className="w-4 h-4" /> Unlimited Products</li>
                                            <li className='white'><Tags className="w-4 h-4" /> Unlimited Categories, Tags and Attributes</li>
                                            <li className='white'><CreditCard className="w-4 h-4" /> Invoice & Payment Gateway Integration (Stripe, PayPal, etc.)</li>
                                            <li className='white'><Edit className="w-4 h-4" /> Free Professional Copywriting</li>
                                            <li><Repeat className="w-4 h-4" /> 4 Hero iterations</li>
                                            <li><PhoneCall className="w-4 h-4" /> Kick-off strategy call</li>
                                            <li><RefreshCw className="w-4 h-4" /> 4x rounds of revisions</li>
                                            <li><Clock className="w-4 h-4" /> Updates every 24 hours</li>
                                            <li><Code className="w-4 h-4" /> React, Laravel or WordPress Development</li>
                                            <li><Smartphone className="w-4 h-4" /> Mobile, Tablet & Desktop layouts</li>
                                            <li className='white'><HeartHandshake className="w-4 h-4" /> 6-months FREE support</li>
                                            <li className='white'><Server className="w-4 h-4" /> Free 1 year Hosting & Domain</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
                <div className="container container-large">
                    <div className="row">
                        <div className="col-12">
                            <div className="pricing-item retainer product">

                                <div className="pricing-item__headline">
                                    <span className='name'>All-in-One Website Growth &amp; Management</span>
                                    <span>Monthly Retainer</span>
                                </div>
                                <div className="pricing-item__price">
                                    <span className='title-gradient'>$999</span>
                                    <span>USD</span>
                                </div>
                                <div className="pricing-item__description">
                                    <p className='small'>
                                        Billed monthly, no lock-ins, cancel anytime.
                                    </p>
                                    <p>
                                        Perfect for entrepreneurs and small to medium-sized businesses who want to focus solely on growing their business, while a dedicated, professional team ensures their website looks exceptional, stays up-to-date, drives traffic<sup>1</sup>, and generates revenue<sup>2</sup>.
                                    </p>
                                </div>

                                <div className="pricing-item__people">

                                    <div className="people-name">
                                        <img src="https://wp.vpixel.co/wp-content/uploads/2024/10/65.jpg" alt="Emma Thompson" loading="lazy" />
                                        <div>
                                            <span className='name'>Emma Thompson</span>
                                            <span className='company'>Founder, FitLife Coaching</span>
                                        </div>
                                    </div>
                                    <div className="people-quote">
                                        <blockquote>
                                            “I have worked with VPixel CO for about a year and a half, and I have truly seen substantial improvements on my website. It’s always up-to-date, fast, and attracts more and more traffic each day, continually enhancing my search ranking.”
                                        </blockquote>
                                    </div>
                                </div>


                                <div className="pricing-item__action">
                                    <a href={constants.site.bookingCall} className="button">Chat with Erick <span className="arrow"><span className="fa fa-arrow-right"></span></span></a>
                                </div>
                                <div className="pricing-item__features">
                                    <p>Our Monthly Retainer Includes:</p>
                                    <ul>
                                        <li><span><FileEdit /></span> Unlimited content updates to keep you ahead of competition</li>
                                        <li><span><Paintbrush /></span> Unlimited professional design updates ($1000+ value/month)</li>
                                        <li><span><Globe /></span> Support for multiple websites - perfect for growing businesses</li>
                                        <li><span><Shield /></span> Premium 24/7 website maintenance & security</li>
                                        <li><span><Clock /></span> 48 hours monthly development time ($4800 value)</li>
                                        <li><span><Zap /></span> VIP fast-track support response</li>
                                        <li><span><TrendingUp /></span> Growth strategy sessions to maximize your ROI</li>
                                        <li><span><BarChart /></span> Comprehensive analytics & performance reports</li>
                                        <li><span><MessageSquare /></span> Enterprise-grade communication tools included</li>
                                    </ul>
                                </div>
                            </div>
                            <p className="small terms">
                                <sup>1</sup>Investment in traffic is not included. <sup>2</sup>Revenue is not 100% guaranteed due to external factors. Secure payments are handled by Paypal for US based customers. We do not store any payment information. All our services are covered by a non-disclosure agreement and protected by a 100% satisfaction guarantee.
                            </p>
                        </div>
                    </div>
                </div>

                <div className="container">
                    <div className="cta">
                        <img src="/images/1665701539301.jpeg" alt="Erick" width="50" />
                        <div style={{ gap: '10px' }} className="d-flex flex-column align-items-center">
                            <span>Questions? Let's talk!</span>
                            <span className="text">
                                Have a call with Erick, one of our founders, to explore how we can help you grow your business in no time!
                            </span>
                            <a href={constants.site.bookingCall} aria-label="Book a Call Now" className="button">Have a 1:1 call with Erick <span className="arrow"><span className="fa fa-arrow-right"></span></span></a>
                        </div>
                    </div>
                </div>
            </section >

        </>
    );
};

export default Pricing;