import React, { useState, useEffect, useCallback } from 'react';
import { Link } from 'react-router-dom';

const useToggleMenu = () => {
    const [isMenuOpen, setIsMenuOpen] = useState(false);

    const handleClickOutside = useCallback((event) => {
        if (isMenuOpen && !event.target.closest('.nav__menu') && !event.target.closest('.navbar-toggler')) {
            setIsMenuOpen(false);
        }
    }, [isMenuOpen]);

    useEffect(() => {
        document.body.classList.toggle('toggle-menu', isMenuOpen);

        document.addEventListener('click', handleClickOutside);
        document.addEventListener('touchstart', handleClickOutside);

        return () => {
            document.removeEventListener('click', handleClickOutside);
            document.removeEventListener('touchstart', handleClickOutside);
        };
    }, [isMenuOpen, handleClickOutside]);

    return {
        isMenuOpen,
        toggleMenu: () => setIsMenuOpen(prev => !prev),
        closeMenu: () => setIsMenuOpen(false)
    };
};

const useScrollEffect = () => {
    useEffect(() => {
        const handleScroll = () => {
            document.body.classList.toggle('scrolling', window.scrollY > 0);
        };

        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, []);
};

const Nav = () => {
    const { toggleMenu, closeMenu } = useToggleMenu();
    useScrollEffect();
    const handleMenuItemClick = useCallback((onClick) => (event) => {
        closeMenu();
        if (onClick) {
            onClick(event);
        }
    }, [closeMenu]);

    const menuItems = [
        { to: "/#about", title: "About VPixel CO" },
        { to: "/#services", title: "Our Services" },
        { to: "/#portfolio", title: "Portfolio & Clients" },
        { to: "/#pricing", title: "Pricing" },
        { to: "/#contact", title: "Contact Us" }
    ];

    return (
        <nav className="nav">
            <div className="nav__container container">
                <div className="logo">
                    <Link aria-label="VPixel CO" title="VPixel CO" className='nostatic' style={{ backgroundImage: `url(../../images/logo-vpx-white.svg)` }} to="/" />
                </div>

                <div className="nav__actions">

                    <a href='/start' aria-label="Start your project here!" className="button">Start your project here! <span className="arrow">
                        <span className="fa fa-arrow-right"></span>
                    </span></a>


                    <button aria-label="Toggle Navigation" className="navbar-toggler" type="button" onClick={toggleMenu}>
                        <span className="navbar-toggler-icon" style={{ backgroundImage: `url(../../images/toggle-button.svg)` }}></span>
                    </button>


                </div>

                <div className="nav__menu">
                    <div className="nav__menu-container">
                        <nav id="NavDropdownMenu" className="menu-main-menu-container">
                            <ul id="menu-main-menu">
                                <li className="menu-title">Menu</li>
                                {menuItems.map(({ to, title, onClick }) => (
                                    <li key={to}>
                                        <Link
                                            to={to}
                                            title={title}
                                            onClick={handleMenuItemClick(onClick)}
                                            onKeyDown={(e) => e.key === 'Enter' && onClick && handleMenuItemClick(onClick)(e)}
                                        >
                                            {title}
                                        </Link>
                                    </li>
                                ))}
                            </ul>
                        </nav>
                    </div>
                </div>
            </div>
        </nav>
    );
};

export default Nav;