import React, { useState } from 'react';
import FormStage from './FormStage';
import NavigationButtons from './NavigationButtons';
import WebsiteTypeSelector from './WebsiteTypeSelector';
import ProgressBar from './ProgressBar';

const LeadCaptureForm = () => {
    const [currentStage, setCurrentStage] = useState(0);
    const [isSubmitted, setIsSubmitted] = useState(false);
    const [formData, setFormData] = useState({
        businessType: '',
        websiteGoal: '',
        websiteType: '',
        currentWebsite: '',
        elevatorPitch: '',
        coreValues: '',
        serviceAreaRegion: '',
        commonQuestions: '',
        uniqueSellingPoints: '',
        warranty: '',
        businessMetrics: '',
        competitors: '',
        desiredFeatures: [],
        budget: '',
        timeline: '',
        references: '',
        additionalInfo: '',
        name: '',
        email: ''
    });
    const [errors, setErrors] = useState({});
    const [setIsLoading] = useState(false);

    const stages = [
        {
            title: 'Business Details',
            fields: [
                {
                    name: 'businessType',
                    label: 'Type of Business',
                    type: 'select',
                    options: ['E-commerce', 'Service Provider', 'Consulting', 'Non-profit', 'Educational', 'Technology', 'Other']
                },
                {
                    name: 'websiteGoal',
                    label: 'Primary Goal for Your Website',
                    type: 'select',
                    options: ['Increase Brand Awareness', 'Generate Leads', 'Sell Products/Services', 'Provide Information', 'Customer Support', 'Other']
                },
                {
                    name: 'websiteType',
                    label: 'Type of Website Desired',
                    type: 'custom',
                    component: WebsiteTypeSelector
                },
                {
                    name: 'currentWebsite',
                    label: 'Current Website URL (Optional)',
                    type: 'text'
                }
            ]
        },
        {
            title: 'Business Profile',
            fields: [
                {
                    name: 'elevatorPitch',
                    label: 'Elevator/Sales Pitch',
                    type: 'textarea',
                    placeholder: 'Briefly describe your business value proposition'
                },
                {
                    name: 'coreValues',
                    label: 'Core Values',
                    type: 'textarea',
                    placeholder: 'List your company\'s core values'
                },
                {
                    name: 'serviceAreaRegion',
                    label: 'Service Area Region',
                    type: 'text',
                    placeholder: 'e.g., Northeast US, Southern California, etc.'
                }
            ]
        },
        {
            title: 'Business Profile',
            fields: [
                {
                    name: 'commonQuestions',
                    label: 'What are questions you get asked the most on the job?',
                    type: 'textarea',
                    placeholder: 'List common customer questions'
                },
                {
                    name: 'uniqueSellingPoints',
                    label: 'Unique Selling Points',
                    type: 'textarea',
                    placeholder: 'What makes your business stand out from competitors?'
                }
            ]
        },
        {
            title: 'Business Profile',
            fields: [
                {
                    name: 'businessMetrics',
                    label: 'Business Facts by the Numbers',
                    type: 'textarea',
                    placeholder: 'List 3-5 key business metrics or achievements'
                },
                {
                    name: 'competitors',
                    label: 'List of 3-5 Direct Competitors',
                    type: 'textarea',
                    placeholder: 'List your main competitors'
                }
            ]
        },
        {
            title: 'Website Features',
            fields: [
                {
                    name: 'desiredFeatures',
                    label: 'Desired Features',
                    type: 'checkbox',
                    options: [
                        'Contact Form',
                        'Blog',
                        'Portfolio',
                        'Booking System',
                        'Google Maps Integration',
                        'Mailchimp Integration',
                        'WhatsApp Integration',
                        'Image Gallery',
                        'Social Media Integration',
                        'Newsletter Signup',
                        'Customer Reviews/Testimonials',
                        'Live Chat',
                        'Search Functionality',
                        'Payment Gateway Integration',
                        'Multi-language Support',
                        'Analytics Integration'
                    ]
                }
            ]
        },
        {
            title: 'Project Details',
            fields: [
                {
                    name: 'budget',
                    label: 'Estimated Budget',
                    type: 'select',
                    options: ['$0 - $500 USD', '$501 - $1,500 USD', '$1,501 - $2,500 USD', '$2,501+ USD']
                },
                {
                    name: 'timeline',
                    label: 'Desired Timeline',
                    type: 'select',
                    options: ['ASAP!', 'Within 2 weeks', 'Within 4 weeks', 'Within 6 weeks', 'More than 8 weeks']
                },
                { name: 'references', label: 'Reference Websites', type: 'textarea' },
                { name: 'additionalInfo', label: 'Additional Information', type: 'textarea' }
            ]
        },
        {
            title: 'Your Information',
            fields: [
                { name: 'companyName', label: 'Company Name', type: 'text' },
                { name: 'name', label: 'Your Name', type: 'text' },
                { name: 'email', label: 'Email Address', type: 'email' },
                { name: 'confirmEmail', label: 'Confirm Email Address', type: 'email' }
            ]
        }
    ];

    const handleInputChange = (e) => {
        const { name, value, type, checked } = e.target;
        if (type === 'checkbox') {
            setFormData(prevData => ({
                ...prevData,
                [name]: checked
                    ? [...(prevData[name] || []), value]
                    : (prevData[name] || []).filter(item => item !== value)
            }));
        } else {
            setFormData(prevData => ({
                ...prevData,
                [name]: value
            }));
        }
    };

    const handleWebsiteTypeChange = (value) => {
        setFormData(prevData => ({
            ...prevData,
            websiteType: value
        }));
    };

    const validateForm = () => {
        const newErrors = {};

        // Validate required fields
        if (!formData.name?.trim()) {
            newErrors.name = 'Name is required';
        }

        if (!formData.email?.trim()) {
            newErrors.email = 'Email is required';
        } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
            newErrors.email = 'Please enter a valid email';
        }

        if (!formData.confirmEmail?.trim()) {
            newErrors.confirmEmail = 'Please confirm your email';
        } else if (formData.email !== formData.confirmEmail) {
            newErrors.confirmEmail = 'Emails do not match';
        }

        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!validateForm()) {
            return;
        }

        setIsLoading(true);

        try {
            const response = await fetch('/api/submit-lead.php', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(formData)
            });

            if (response.ok) {
                setIsSubmitted(true);
            } else {
                throw new Error('Failed to submit form');
            }
        } catch (error) {
            console.error('Error submitting form:', error);
            alert('Sorry, there was an error submitting your form. Please try again.');
        } finally {
            setIsLoading(false);
        }
    };

    if (isSubmitted) {
        return (
            <div className="lead-capture-form">
                <div className="success-message">
                    <div className="success-icon">✓</div>
                    <h2>All sent!</h2>
                    <p>We have received your info. Keep and eye to your email since  we'll be in touch with you shortly!</p>
                </div>
            </div>
        );
    }

    return (
        <div className="lead-capture-form">
            <ProgressBar currentStage={currentStage} totalStages={stages.length} />
            <form onSubmit={handleSubmit}>
                <FormStage
                    stage={stages[currentStage]}
                    formData={formData}
                    onChange={handleInputChange}
                    onWebsiteTypeChange={handleWebsiteTypeChange}
                    errors={errors}
                />
                <NavigationButtons
                    currentStage={currentStage}
                    totalStages={stages.length}
                    onNext={() => setCurrentStage(prev => Math.min(prev + 1, stages.length - 1))}
                    onBack={() => setCurrentStage(prev => Math.max(prev - 1, 0))}
                    onSubmit={handleSubmit}
                />
            </form>
        </div>
    );
};

export default LeadCaptureForm;

