import React from 'react';

const NavigationButtons = ({ currentStage, totalStages, onNext, onBack, onSubmit }) => {
    return (
        <div className="navigation-buttons">
            {currentStage > 0 && (
                <button type="button" onClick={onBack} className="back-button button--bordered button">
                    Back
                </button>
            )}
            {currentStage < totalStages - 1 ? (
                <button type="button" onClick={onNext} className="next-button button">
                    Next
                </button>
            ) : (
                <button type="submit" onClick={onSubmit} className="submit-button button">
                    Get your free quote!
                </button>
            )}
        </div>
    );
};

export default NavigationButtons;

