import React from 'react'
import './Faqs.css';
import constants from '../../constants.json';
const faqs = [
    {
        title: '<span>01</span>  Who is VPixel CO?',
        content: '<p>VPixel CO is a boutique web design and development studio crafting exceptional digital experiences. Led by two seasoned design engineers with over a decade of combined expertise, we partner with forward-thinking organizations worldwide, including:</p><ul><li>Innovative startups</li><li>Visionary entrepreneurs</li><li>Growing small and mid-size companies</li><li>Ambitious early-stage ventures</li><li>Purpose-driven NGOs</li></ul><p>We specialize in creating high-impact digital assets like landing pages, WordPress websites, and sophisticated online stores. Our talented in-house team includes:</p><ul><li>4 UX/UI designers</li><li>5 full-stack web developers</li><li>2 certified project managers</li><li>2 conversion-focused copywriters</li><li>2 digital marketing specialists</li></ul><p>Together, we combine deep technical expertise with creative excellence to build digital products that drive real business results.</p><p>For our retainer clients, we maintain an elite network of 20+ vetted industry professionals ready to support your digital growth at scale.</p>'
    },
    {
        title: '<span>02</span>  How much time do we need to develop a website?',
        content: '<p>Depending on the project, a website can take anywhere from 2 to 6 weeks to complete. Here\'s a breakdown:</p><ul><li>Landing pages: approximately 2 weeks</li><li>Marketing and corporate websites: 3 to 4 weeks</li><li>E-commerce stores: 4 to 6 weeks</li></ul><p>We will provide a precise timeline once we have the project details.</p>'
    },
    {
        title: '<span>03</span>  Why do we charge those prices?',
        content: '<p>Our pricing reflects the exceptional value we deliver: Every website is custom-built from the ground up by our team of world-class professionals who are passionate experts in their respective fields. This ensures your business gets a truly unique, personalized design that stands out.</p><p>Creating an effective website requires extensive planning, research, and meticulous execution. Unlike many agencies, <strong className="color-yellow">we never use pre-made templates</strong> - everything is crafted specifically for you.</p><p>Thanks to our strategic location in Colombia, we\'re able to offer premium quality at rates significantly more competitive than US or European agencies, where similar services typically cost between $5,000 and $15,000 – <a href="https://www.webfx.com/web-design/pricing/wordpress-cost/#6183ff453fb81-1" target="_blank" rel="noopener noreferrer">learn more</a></p>'
    },
    {
        title: '<span>04</span>  Do you offer a payment plan?',
        content: '<p>Yes, depending on the project, we most likely will divide the payment into two parts:</p><ol><li>The first 50% is typically made once the client is ready to proceed.</li><li>The remaining 50% is due 20 calendar days after the first payment or upon project delivery, whichever comes first.</li></ol><p>Or, if the client prefers or the project is smaller, we can split the payment into 2 parts but the final part will be due when the project is delivered. Read more about this in our <a href="http://vpixel.co/terms" target="_blank" rel="noopener noreferrer">Terms and Conditions</a></p>'
    },
    {
        title: '<span>05</span>  Do you offer a discount for non-profit organizations?',
        content: '<p>Yes, we offer a range of discount from 10% to 30% for non-profit organizations. Please contact us to discuss your project and how we can help you achieve your goals.</p>'
    },

    {
        title: '<span>06</span>  What do you need to start a project?',
        content: '<p>The requirements vary depending on the type of website the client is looking for. A detailed discussion will take place during the discovery call before starting the collaboration. Depending on the agreed package, the following elements might be necessary:</p><ul><li>Photos of the client\'s team</li><li>Images of the client\'s workplace</li><li>Website content such as text, PDFs, etc.</li><li>Profiles of the client\'s staff</li><li>Any videos the client wants to include on the website</li><li>Login details for the client\'s current website</li></ul><p>Some of this information might already be available on the client\'s current website, in which case, it can be easily transferred to the new site.</p>'
    },
    {
        title: '<span>07</span>  What if I dont like the design?',
        content: '<p>In a unlikely event that you don\'t like the design, we will iterate the design or explore new ideas and creative directions until you are 100% satisfied at no additional cost. More details can be found in our <a href="http://vpixel.co/terms" target="_blank" rel="noopener noreferrer">Terms and Conditions</a></p>'
    }
]

const Faqs = () => {
    return (

        <div className="faqs static">
            <div id="faqs" style={{ position: 'relative', top: '-100px' }}></div>
            <div className="container">


                <div className="row">
                    <div className="col-12">
                        <div className="title">
                            <div>
                                <span className="subtitle">We hope you have it all clear, but it wont hurt if we ask again...</span>
                                <h2 className="title-gradient">Questions? <span className="color-yellow">We got you covered!</span></h2>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="col-12">
                        <div className="faqs__container grid">
                            <div className="faqs__content">
                                <div className="accordion" id="accordionFaqs">
                                    {faqs.map((faq, index) => (
                                        <div className="accordion-item faq-item box" key={index}>
                                            <h2 className="accordion-header" id={`header-${index}`}>
                                                <button
                                                    className="accordion-button collapsed"
                                                    type="button"
                                                    data-bs-toggle="collapse"
                                                    data-bs-target={`#content-${index}`}
                                                    aria-expanded="false"
                                                    aria-controls={`content-${index}`}
                                                >
                                                    <div className='faq-title' dangerouslySetInnerHTML={{ __html: faq.title }} />
                                                </button>
                                            </h2>
                                            <div
                                                id={`content-${index}`}
                                                className="accordion-collapse collapse"
                                                aria-labelledby={`header-${index}`}
                                                data-bs-parent="#accordionFaqs"
                                            >
                                                <div className="accordion-body" dangerouslySetInnerHTML={{ __html: faq.content }} />
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                            <div className="faqs__call d-flex flex-column">
                                {/* <img className="img-fluid" src="/images/people/erick-2.webp" alt="" /> */}
                                <img src="/images/1665701539301.jpeg" alt="Erick" />

                                <h4 className='title-gradient'>Have even more questions?</h4>
                                <p>Don't worry! Erick, one of our founders, is ready to assist with any additional question you could have, so click the button below so we can chat in WhatsApp and get you started!</p>
                                <a
                                    className="button"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    href={constants.site.email}
                                >
                                    <span style={{ marginRight: '5px' }} className="fa-solid fa-paper-plane"></span> Drop us an email!
                                </a>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>


    )
}

export default Faqs;
