import React from 'react';

const slides = [
    {
        pretitle: 'High quality ✨, low cost 💰',
        image: "/images/cards/1.webp",
        title: "Top tier UI/UX design, professional copy and modern technology <span class='color-yellow'>budget friendly</span>",
        description: "<p>Thanks to our location in Colombia 🇨🇴, we offer competitive pricing and high-quality technology, design and copy, leveraging the value of the USD to deliver top-tier results at a fraction of the cost.</p><p>Do not pay <a href='https://www.webfx.com/web-design/pricing/wordpress-cost/#6183ff453fb81-1' target='_blank'>thousands of dollars</a> for a website that doesn't convert, we offer a <a href='/#pricing'>budget friendly</a> solution.</p>"
    },
    {
        pretitle: 'AI-powered 🤖, human touch 🙋',
        image: "/images/cards/5.webp",
        class: "",
        title: "Improving content <span class='color-yellow'>fast and easy</span> with AI collaboration",
        description: "<p>We co-develop each website with AI tools like Midjourney, Ideogram and ChatGPT to create (or generate it if you don't have it) high-quality content, generate custom photos and text, and deliver a powerful sales tool at an affordable price.</p>"
    },
    {
        pretitle: 'Return on investment 💰',
        image: "/images/cards/2.webp",
        title: "A <span class='color-yellow'>ROI-Focused</span> mindset in every project",
        description: "<p>We craft each website with a clear and concise design, ensuring that every element contributes to your business goals. Our websites are designed to be user-friendly, engaging, and easy to navigate, providing a seamless experience for your visitors.</p>"
    },
    {
        pretitle: 'Empowering your unique brand 💼',
        image: "/images/cards/3.webp",
        title: "Making your brand <span class='color-yellow'>stand out</span>",
        description: "<p>We take your brand's unique features and turn them into a powerful sales and conversions tool, creating a memorable experience that resonates with your target audience, we say no-no to predesigned templates.</p>"
    },
    {
        pretitle: 'Know your audience 📊',
        image: "/images/cards/4.webp",
        title: "Allowing to collect relevant data from <span class='color-yellow'>your visitors</span>",
        description: "<p>We not only create amazing websites, but we also allow you to collect relevant data from your visitors, capturing their interests, needs, and preferences through strategic forms, analytical tools, and demographic data.</p>"
    },
];

const Slider = () => {
    return (
        <>
            {slides.map(({ image, title, description, pretitle, class: slideClass }, index) => (
                <div className={`slide-content ${slideClass || ''}`}>
                    <figure className="nostatic" style={{ backgroundImage: `url(${image})` }} />
                    <div className='d-flex flex-column gap-2'>
                        <span className='pretitle'>{pretitle}</span>
                        <h3 dangerouslySetInnerHTML={{ __html: title }} />
                        <span dangerouslySetInnerHTML={{ __html: description }} />
                    </div>

                </div>
            ))}
        </>
    );
};

export default Slider;