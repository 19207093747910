import React from "react";
import SEO from '../../components/SEO/SEO';

const Terms = () => {
    return (
        <>
            <SEO
                title="Terms and Conditions - VPixel CO"
                description="Our terms and conditions for all our services and products."
                ogImage="https://static.vpixel.co/OG-v2-Final-EN.jpg"
                favicon="https://static.vpixel.co/favicon-32x32.png"
                appleFavicon="https://static.vpixel.co/apple-touch-icon.png"
            />

            <div className="terms-container">
                <div className="container">
                    <nav>
                        <div className="nav nav-tabs" id="nav-tab" role="tablist">
                            <button className="nav-link active" id="" data-bs-toggle="tab" data-bs-target="#english" type="button" role="tab" aria-controls="english" aria-selected="false">English</button>
                            <button className="nav-link" id="" data-bs-toggle="tab" data-bs-target="#spanish" type="button" role="tab" aria-controls="spanish" aria-selected="true">Español</button>
                        </div>
                    </nav>
                    <div className="tab-content" id="nav-tabContent">
                        <div className="tab-pane fade show active" id="english" role="tabpanel" aria-labelledby="english" tabindex="0">

                            <div className="title">
                                <h2 className="title-gradient">
                                    Terms and Conditions of <span className="color-yellow">VPixel CO Services</span>
                                </h2>
                            </div>
                            <p>These are the standard Terms and Conditions for Website Design and Development, Graphic Design, Domain Name Registration, SEO (Search Engine Optimisation), and Web Hosting. The Customer agrees to these terms stated upon beginning any of the services stated above, or otherwise provided by VPixel CO.</p>
                            <h3 className="h3-title color-yellow" id="h.j2rw60eo5r2w">General:</h3>
                            <p>It is solely the responsibility of the Customer to keep VPixel CO informed of any changes in their contact details. Failure to do so may result in service expiration or the inability to continue rendering services.</p>
                            <p>An account with VPixel CO is intended for use solely by the Customer associated with the account. Individual account holders are prohibited from selling, reselling, storing, or transferring VPixel CO services to third parties.</p>
                            <p>VPixel CO reserves the right to refuse its services and/or products to anyone. This includes the right to suspend or cancel a Customer's access to existing services if there is a violation of this Terms Agreement or if VPixel CO, at its absolute discretion, determines that the services have been abused or used inappropriately.</p>
                            <p>All invoices, quotes, service announcements, and any other notifications will be sent to the Customer via email.</p>

                            <h3 className="h3-title color-yellow" id="h.9xm4kp7lw3vn">Payments</h3>
                            <p>All payments are made in USD for International Customers, and in COP for Colombian Customers.</p>
                            <p>All payments are made following the <a href="#afpes">Financial Arrangements for Projects</a>, except for the Retainer, which is paid in installments of the same amount each month. Regarding the retainer, payments must be made in advance at the start of the month, specifically between the 1st and 5th of the month. If the Customer fails to make the payment within this timeframe, the Retainer will be paused until the payment is made and all work will be paused until the Retainer is reinstated.</p>
                            <p>Our payments are processed through PayPal for International Customers, and through Bancolombia for Colombian Customers. The authorized PayPal account is: <a href="https://paypal.me/vpixelco" target="_blank" rel="noreferrer">https://paypal.me/vpixelco</a> with the name of Erick Acevedo @vpixelco</p>

                            <h3 className="h3-title color-yellow" id="h.2pq3k06ncnjb">Cancellations &amp; Refunds:</h3>
                            <p>At VPixel CO, we do not offer refunds as we are committed to working closely with our clients through multiple iterations until they are 100% satisfied with our deliverables. We believe in maintaining open communication and making continuous improvements to ensure our work fully aligns with your expectations and business goals.</p>
                            <p>
                                We will work with you to refine and improve the design through multiple iterations at no additional cost. This includes exploring alternative creative directions and making adjustments based on your feedback. While we aim for complete satisfaction, we typically consider 2-5 rounds of revisions to be reasonable, allowing us to maintain project timelines while ensuring your vision is achieved.
                            </p>
                            <p>All deposits and fees charged on a pre-pay basis, as well as progress payments made during the course of our services, are non-refundable. This helps us manage the resources and time allocated to your project.</p>
                            <p>Refunds may also be issued at the discretion of VPixel CO in cases where we fail to deliver the described service. If any design work has been signed off prior to website construction, no cancellations will be accepted, and all associated fees (excluding the final invoice balance) must be paid in full. The final invoice balance, which represents a percentage of the total cost as specified in the initial quote, is due upon completion of the service. This balance is also non-refundable.</p>
                            <p>We understand that every business has unique needs, and while software limitations (such as with WordPress, WP eCommerce, ShopPlugin, or Prestashop) may arise, these do not justify withholding final payments. If you require specific functionality that goes beyond the original agreement, VPixel CO offers additional services to meet your needs, which can be arranged for an extra fee.</p>
                            <p>If your website or service is discontinued due to cancellation or expiration, we offer the option to reinstate it within a 12-month period for a reconnection fee of $20 USD. For any recurring services, such as hosting or maintenance, cancellations must be communicated at least 30 days in advance. Payments made in advance for these services are non-refundable. Service cancellations must be formally requested through VPixel CO, as we do not process cancellations without notice.</p>
                            <p>In the event a refund is approved, the processing time may vary based on your bank. Typically, refunds will reflect in your account within 2 to 5 business days. However, customers who bank with institutions other than Bancolombia may experience longer processing times.</p>
                            <h3 className="h3-title color-yellow" id="h.lefwvelwr5ey">Website Design, Development &amp; Support:</h3>
                            <p>Upon receiving the design brief, the Customer will be provided with a website design mock-up via a FIGMA link. The Customer is entitled to request a reasonable number of changes to the design concepts until satisfaction is achieved, with approximately 2 to 5 revisions considered normal.</p>
                            <p>The website, including all graphics and programming code, remains the property of VPixel CO until all outstanding payments have been made in full. If the Customer has purchased a logo design from VPixel CO as part of the website package, the logo must be approved before work on the website design begins.</p>
                            <p>VPixel CO builds WordPress websites to be self-managed, meaning we are not responsible for editing, updating, or maintaining the Customer's website unless otherwise agreed upon in a separate contract.</p>
                            <p>All websites and landing pages will be delivered as templates with placeholder text and images or previously provided content. The Customer is solely responsible for inserting the final copy, products, and images. While the Customer will retain full ownership of the website's content and design once the final balance has been paid, certain design elements remain the intellectual property of VPixel CO.</p>
                            <p>We strongly encourage the Customer to securely save the final website assets and perform regular backups. VPixel CO is not responsible for maintaining or storing these deliverables indefinitely, and it is advisable for the Customer to ensure they have access to all necessary files.</p>
                            <p>To assist with managing the website, VPixel CO can provide a manual (PDF or recorded video) for guidance on how to update and maintain the website. Additionally, two hours of WordPress CMS training is included with every website design quote. Further training sessions are available at a rate of $10 USD per hour.</p>
                            <p>For customers who have purchased hosting services through VPixel CO, each website account will be allocated a specific amount of bandwidth and disk space. Any usage exceeding the allocated amount will incur additional charges. VPixel CO monitors bandwidth and disk space usage and, if necessary, will automatically increase the allocation to ensure smooth operation. Please consult our website for details on allocations and pricing. While we monitor usage, VPixel CO cannot be held responsible if a website goes offline due to exceeding the bandwidth or disk space limits. However, we will work to restore the website to its previous working state, provided the Customer updates or renews their hosting service.</p>
                            <p>All websites created by VPixel CO include a built-in authorship anchor tag in the footer, linking back to our website. This link must remain in place throughout the contract period with VPixel CO.</p>


                            <h3 className="h3-title color-yellow" id="h.x7k9p4n2m5vy">For the Retainer Monthly Subscription:</h3>
                            <p>The VPixel CO Monthly Retainer Service (“Service”) is an all-in-one subscription providing a comprehensive suite of digital services on a monthly basis to address the dynamic needs of our clients. The service includes UI/UX design, professional copywriting, SEO and marketing strategy, content updates, website maintenance, and more, designed to support clients in managing and enhancing their online presence.</p>
                            <p>The Customer is responsible for covering all costs associated with any advertising campaigns, social media promotions, and other marketing initiatives aimed at driving traffic to their website. This includes but is not limited to paid ads, sponsored content, and third-party marketing tools or services outside the scope of this agreement.</p>
                            <p>The retainer fee is billed monthly, beginning on the date of sign-up, and will auto-renew unless canceled by either party. All fees are due in full before service hours can be utilized each month. This fee covers up to 48 hours of dedicated work per month. Should the client require work beyond this monthly limit, additional charges may apply, or a separate agreement may be required to accommodate the extra hours.</p>
                            <p>Under the terms of this service, VPixel CO will provide ongoing updates, revisions, maintenance, and content creation for the client’s websites. A dedicated Project Manager will oversee project scope, ensuring that deliverables meet client expectations and managing the coordination and prioritization of tasks according to the client’s needs. All requests for updates or revisions will be accommodated as scheduling allows, and reasonable timelines will be discussed for each task.</p>
                            <p>The “unlimited” services offered within this retainer, including website updates and maintenance, are limited by the monthly hour allocation. VPixel CO reserves the right to prioritize tasks based on project scope, urgency, and availability to maximize the value of the allocated time each month.</p>
                            <p>VPixel CO provides priority support 24/7 for urgent matters. General inquiries and updates will receive responses during standard business hours, and communication will be facilitated through a combination of Slack, Loom, asynchronous methods, and scheduled meetings, as agreed upon with the client.</p>
                            <p>Either party may terminate the service at any time, with all work concluding on the final day of the current billing period. Refunds are not available for partial months or unused hours; however, the retainer can be paused and reinstated at the client’s request.</p>
                            <p>VPixel CO is committed to maintaining the confidentiality of all client-provided information, including sensitive data, and to upholding the highest standards of data privacy and protection.</p>

                            <h3 className="h3-title color-yellow" id="h.mtgsfrf5lr8u">Warranty:</h3>
                            <p>At VPixel CO, we offer a 90-day warranty from the date of your website's completion. During this period, any bugs or defects that are reported to us will be repaired at no additional cost. Once the 90-day warranty period has expired, VPixel CO will no longer be responsible for addressing bugs or defects unless otherwise specified under the terms of your website hosting agreement. Any changes, fixes, or bugs reported outside of this warranty period may be subject to additional charges.</p>
                            <p>For hosting-related issues, we provide a manufacturer's warranty for 12 months from the date your website goes live. This warranty covers issues related to server updates, restarts, and patches. VPixel CO will assess whether an issue constitutes a hosting-related bug under this warranty and address it accordingly.</p>
                            <p>We are committed to resolving all issues reported to us within 48 hours of notification, or upon acceptance of a quote for applicable fixes.</p>
                            <p>Our services extend to web browsers and email clients released within the 12 months preceding the completion date of your website or email newsletter. If compatibility is required with older browsers or clients, additional charges may apply.</p>
                            <p>Please note, we are not liable for email newsletters or HTML-based emails that do not display correctly due to limitations in the email client, program, or application being used, particularly if they do not support current industry standards for email development.</p>
                            <p>Additionally, VPixel CO is not responsible for bugs or issues that arise if the website is moved from its originally installed server without prior notification to us, unless such issues are covered under a previously agreed-upon warranty.</p>
                            <h3 className="h3-title color-yellow" id="h.mtgsfrf5lr8u">Financial Arrangements for Projects:</h3>
                            <p>The cost of the VPixel CO Website (including Brochure Websites, Shopping Cart Websites, and Landing Pages) is a fixed price for design and installation. VPixel CO guarantees no ongoing fees unless otherwise agreed upon in a separate contract.</p>
                            <p>Quoted estimates are valid for 30 days from the date of issue. Our payment schedule is as follows:</p>
                            <ul>
                                <li>50% of the total project cost as a deposit before work begins.</li>
                                <li>The remaining 50% of the total cost will be invoiced either 20 calendar days after the initial payment or upon project completion and testing, marking the start of the 90-day warranty period, whichever occurs first.</li>
                            </ul>
                            <p>If the client request it or the project cost is lower than $500 USD, we can provide a payment plan in which we have 50% as a deposit and the remaining 50% is due upon project completion and testing, marking the start of the 90-day warranty period.</p>
                            <p>If there are any variations or extensions to the project, or if the Customer wishes to engage VPixel CO on a time and material basis, all work will be charged in a new quote that will be sent to the Customer and approved by them.</p>
                            <h3 className="h3-title color-yellow" id="h.n8njn2tt0psa">Graphic Design:</h3>
                            <p>The deposit for graphic design work is due upon acceptance of the quote. The remaining balance is due when the artwork has been approved, prior to the final delivery of graphical files.</p>
                            <p>Any additional changes requested after sign-off will be quoted and billed separately.</p>
                            <p>For logo design services, Customers will receive multiple design concepts via email based on the design brief. Customers can request a reasonable number of changes to their chosen concepts until they are satisfied with the design. Approximately 2-5 rounds of changes are considered normal.</p>
                            <p>Upon final approval of the design, the Customer will receive the final graphic design files via email or other agreed-upon delivery methods.</p>
                            <p>VPixel CO provides all artwork in print-ready files for the Customer's use. It is the Customer's responsibility to verify all content, contact details, email addresses, phone numbers, or any other requested information for accuracy. VPixel CO will not be held legally or otherwise responsible for any errors on the artwork that were not noticed prior to its use, including spelling, grammar, color issues, or typing errors. We recommend obtaining a "Chem Proof" from your printer before finalizing the print run.</p>
                            <p>All graphic designs created by VPixel CO are the copyright of the Customer. Ownership will be transferred to the Customer upon receipt of full payment.</p>
                            <h3 className="h3-title color-yellow" id="h.uf0cvd8z6zot">Domain Registration:</h3>
                            <p>There is no guarantee made by VPixel CO that the requested domain name(s) are available or can be registered under the requested suffix. We are not liable for any domain name requirements, including registration, renewal, and transfer.</p>
                            <h3 className="h3-title color-yellow" id="h.i51dvvuq4moc">Hosting Services:</h3>
                            <p>VPixel CO expressly hosts code written only by VPixel CO developers (past or present).</p>
                            <p>We guarantee the health of WordPress Websites built by VPixel CO for a period of one year while hosted with us, covering only browser updates and styling issues. It is at our discretion to determine which issues are covered by this guarantee. Fixes for these issues are provided at no charge. Any fixes or changes outside these stipulations are billable.</p>
                            <p>Invoices for Yearly Hosting Services are invoiced for a month in advance and are due within 30 days of issue. Failure to pay the balance may result in a service interruption.</p>
                            <p>We assume no responsibility for timelines, deletion, mis-delivery, or failure to store your communications, personalization settings, or any other information stored on your hosting service.</p>
                            <p>VPixel CO is not liable for any content linked or embedded from an external source that may disappear due to server connection interruption. Additionally, we reserve the right to remove any linked or embedded content that breaches copyright or other laws without notice to the Customer.</p>
                            <p>In the event of server compromise, we will endeavor to restore the Customer's website to its previous state as soon as possible but are not responsible for lost content during restoration.</p>
                            <p>We are not liable for compromises due to outdated WordPress software, plugins, or browser software. If a compromise occurs due to outdated WordPress software and/or plugins, the Customer will be billed for updating the website installation.</p>
                            <p>Refusal of WordPress and plugin updates voids all associated warranties and guarantees with Digital Developments.</p>
                            <p>Customers are not contractually obligated to remain hosting with VPixel CO, and services may be terminated at any time. Bills paid in advance are non-refundable, and all outstanding invoices must be paid in full.</p>
                            <p>Additional capacity and bandwidth can be purchased for a pre-set fee and apply until the next invoice for renewal or until a month has passed, whichever comes first.</p>
                            <p>VPixel CO reserves the right to change hosting package prices without notice. Existing hosting services will not be affected by any pricing changes unless the Customer is otherwise notified.</p>
                            <p>If the Customer requests their website to be placed on another server, a Set-Up Fee may be incurred based on the server type, location, and website complexity.</p>
                            <p>You are responsible for keeping a copy of any existing website we may replace pursuant to web design services, including all databases and hosted files. We can provide an archival solution for the existing website at our standard commercial rates.</p>
                            <h3 className="h3-title color-yellow" id="h.ys5mg0cyelfr">Transfer:</h3>
                            <p>This agreement may not be transferred under any circumstances without the written consent of VPixel CO.</p>
                            <p>VPixel CO websites can be transferred away from our servers at any time, but all warranties become void, and we will no longer be liable for the website or its contents. Domain names will always remain the property of the Customer and are registered under the Customer who ordered them whenever possible.</p>
                            <p>This Terms Agreement establishes the absolute understanding of all parties involved. Upon the renewal of services covered by this Agreement, any changes or modifications made during the term of service are agreed to by both parties.</p>
                            <p>This Agreement constitutes the entire understanding of the parties. Any changes or modifications to this Contract are agreed to by both parties upon renewal of services.</p>


                            <h3 className="h3-title color-yellow" id="h.zap3n93vjoan">Taxes:</h3>
                            <p>VPixel CO shall not be liable for any taxes or other fees related to sales made by the Customer using our server. The Customer agrees to take full responsibility for all taxes and fees associated with such products sold by them.</p>
                            <h3 className="h3-title color-yellow" id="h.irqtv8bkxbmj">Hardware, Equipment &amp; Software:</h3>
                            <p>It is assumed that the Customer has the necessary knowledge to maintain the VPixel CO WordPress website. The Customer agrees that it is not the responsibility of VPixel CO to provide this knowledge or maintain the website on behalf of the Customer without the purchase of additional services and the previously defined support.</p>
                            <p>VPixel CO makes no guarantees, assurances, or warranties regarding the compatibility of the Customer's computer equipment and/or software with any VPixel CO product or service.</p>
                            <p>It is the Customer's sole responsibility to provide all personal or company details necessary for accessing VPixel CO websites.</p>
                            <h3 className="h3-title color-yellow" id="h.4sneltfmjq8j">Limitations of Liability:</h3>
                            <p>VPixel CO is not liable to the Customer (whether under contract or otherwise) under the following stipulations:</p>
                            <ul>
                                <li>There is no express guarantee from any party, affiliate, agent, merchants, or licensors, etc., that any VPixel CO services or products will be uninterrupted or error-free.</li>
                                <li>There is no express guarantee regarding the accuracy, reliability, or content of any information or service contained in or provided through VPixel CO servers (including websites) unless otherwise expressly stated in this agreement.</li>
                                <li>VPixel CO is not responsible for any damage to the Customer's computer systems or loss of data resulting from the download, use, or distribution of any material – digital or otherwise – from our websites or any affiliates or active clients.</li>
                                <li>VPixel CO, including its officers, agents, employees, etc., is not liable (including through negligence) for any direct, indirect, incidental, special, or consequential damages resulting from the use or inability to use our services, or from mistakes, omissions, interruptions, deletion of files, errors, defects, delays in operation or transmission, or any failure of performance, whether or not limited to acts of God, communication failure, theft, destruction, or unauthorized access to our records, programs, servers, or services.</li>
                                <li>Regardless of the above, the Customer's exclusive remedies for all damages, losses, and causes of actions, whether in contract, tort (including negligence), or otherwise, shall not exceed the combined dollar amount paid by the Customer during the term of this Agreement, plus any reasonable attorney's fees and court costs.</li>
                            </ul>
                            <h3 className="h3-title color-yellow" id="h.vsqpbnvoq41n">Disclaimer:</h3>
                            <p>VPixel CO and its parents, subsidiaries, affiliates, officers, directors, shareholders, employees, and agents shall not be liable, under any circumstances or legal theories whatsoever, for:</p>
                            <ul>
                                <li>Any loss of business, profits, or goodwill, loss of use or data, interruption of business, or any indirect, special, incidental, or consequential damages of any character, even if VPixel CO is aware of the risk of such damages, resulting in any way from the Customer's use or inability to use the online services or software, or resulting from errors, defects, omissions, delays in operation or transmission, or any other failure of performance of the website, servers, or software.</li>
                                <li>VPixel CO disclaims any warranty of merchantability or fitness for a particular purpose and will not be responsible for any damages suffered by the Customer, including loss of data resulting from delays, non-deliveries, or service interruptions by any cause or errors or omissions of the Customer.</li>
                                <li>VPixel CO expressly limits its damages to the Customer for any non-accessibility time or other downtime to the pro-rata monthly or yearly charge during the service unavailability. VPixel CO specifically denies any responsibility for damages arising as a consequence of such unavailability.</li>
                                <li>Use of any information obtained through VPixel CO is at the Customer's own risk, and VPixel CO specifically denies any responsibility for the accuracy or quality of information obtained through our services.</li>
                                <li>VPixel CO cannot be held liable for nor control the actions of website users, visitors, or linked third parties.</li>
                            </ul>
                            <h3 className="h3-title color-yellow" id="h.ptl9fkr9rerb">Goodwill:</h3>
                            <p>This proposal assumes goodwill from both parties regarding:</p>
                            <ul>
                                <li>Reasonable expectations for achievements within a given timeframe.</li>
                                <li>Utilizing resources effectively to achieve optimal outcomes.</li>
                            </ul>
                            <h3 className="h3-title color-yellow" id="h.vbys59478gin">Trademarks &amp; Copyrights:</h3>
                            <p>The Customer warrants the right to use any applicable trademarks.</p>
                            <h3 className="h3-title color-yellow" id="h.sbtay2jk1dtj">Indemnification:</h3>
                            <p>The Customer agrees to defend, indemnify, and hold VPixel CO harmless from any demands, liabilities, losses, costs, and claims, including reasonable attorneys' fees, asserted against VPixel CO, its agents, customers, servants, officers, and employees, arising from:</p>
                            <ul>
                                <li>Any services provided or performed, or agreed to be performed, by the Customer, its agents, employees, or assigns.</li>
                                <li>Injury to person or property caused by products sold or distributed in connection with VPixel CO's server.</li>
                                <li>Material supplied by the Customer infringing on the proprietary rights of a third party.</li>
                                <li>Copyright infringement.</li>
                                <li>Defective products sold on VPixel CO's server.</li>
                            </ul>
                            <h3 className="h3-title color-yellow" id="h.yynhkhqsct24">Governing Laws &amp; Severability:</h3>
                            <p>This agreement shall be governed by the laws of Bogotá, Colombia. All parties irrevocably submit to the non-exclusive jurisdiction of the courts of Colombia.</p>
                            <h3 className="h3-title color-yellow" id="h.vm5k4rtqkvds">Contract Revisions:</h3>
                            <p>Revisions to this Contract will be applicable to previous Contracts. Revisions will be considered agreed to by the Customer upon renewal of VPixel CO services as specified in Section 2: Cancellation &amp; Refund.</p>
                        </div >
                        <div className="tab-pane fade" id="spanish" role="tabpanel" aria-labelledby="spanish" tabindex="1">

                            <div>
                                <div className="title">
                                    <h2 className="title-gradient">
                                        Términos y Condiciones de los Servicios de <span className="color-yellow">VPixel CO</span>
                                    </h2>
                                </div>


                                <p>Estos son los Términos y Condiciones estándar para Diseño y Desarrollo de Sitios Web, Diseño Gráfico, Registro de Nombres de Dominio, SEO (Optimización de Motores de Búsqueda) y Alojamiento Web. El Cliente acepta estos términos al comenzar cualquiera de los servicios mencionados anteriormente, o cualquier otro servicio proporcionado por VPixel CO.</p>
                                <h3 className="h3-title color-yellow" id="h.j2rw60eo5r2w">General:</h3>
                                <p>Es responsabilidad exclusiva del Cliente mantener informado a VPixel CO de cualquier cambio en sus datos de contacto. No hacerlo puede resultar en la expiración del servicio o la imposibilidad de continuar prestando servicios.</p>
                                <p>Una cuenta con VPixel CO está destinada al uso exclusivo del Cliente asociado a la cuenta. Los titulares de cuentas individuales tienen prohibido vender, revender, almacenar o transferir servicios de VPixel CO a terceros.</p>
                                <p>VPixel CO se reserva el derecho de rechazar sus servicios y/o productos a cualquier persona. Esto incluye el derecho de suspender o cancelar el acceso de un Cliente a los servicios existentes si hay una violación de este Acuerdo de Términos o si VPixel CO, a su absoluta discreción, determina que los servicios han sido abusados o utilizados de manera inapropiada.</p>
                                <p>Todas las facturas, cotizaciones, anuncios de servicio y cualquier otra notificación se enviarán al Cliente por correo electrónico.</p>

                                <h3 className="h3-title color-yellow" id="h.9xm4kp7lw3vn">Pagos</h3>
                                <p>Todos los pagos se realizan en USD para Clientes Internacionales, y en COP para Clientes Colombianos.</p>
                                <p>Todos los pagos se realizan siguiendo los <a href="#afpes">Arreglos Financieros para Proyectos</a>, excepto por el Retainer, que se paga en cuotas del mismo valor cada mes. Con respecto al Retainer, los pagos deben realizarse por adelantado al inicio del mes, específicamente entre el 1 y el 5 del mes. Si el Cliente no realiza el pago dentro de este plazo, el Retainer se pausará hasta que se realice el pago y todo el trabajo se detendrá hasta que el Retainer sea reestablecido.</p>

                                <p>Nuestros pagos se procesan a través de PayPal para Clientes Internacionales, y a través de Bancolombia para Clientes Colombianos. La cuenta autorizada de PayPal es: <a href="https://paypal.me/vpixelco" target="_blank" rel="noreferrer">https://paypal.me/vpixelco</a> con el nombre de Erick Acevedo @vpixelco</p>

                                <p>Para clientes Colombianos y/o ubicados en Colombia, ofrecemos todos nuestros productos ofertados en la página principal de VPixel CO, específicamente en la sección de <a href="/#pricing">"Ready to get Started"</a> con un descuento especial, por favor comunicate con nosotros para obtener más información.</p>


                                <h3 className="h3-title color-yellow" id="h.2pq3k06ncnjb">Cancelaciones y Reembolsos:</h3>
                                <p>
                                    En VPixel CO, no ofrecemos reembolsos ya que estamos comprometidos a trabajar estrechamente con nuestros clientes a través de múltiples iteraciones hasta que estén 100% satisfechos con nuestros entregables. Creemos en mantener una comunicación abierta y realizar mejoras continuas para asegurar que nuestro trabajo se alinee completamente con sus expectativas y objetivos comerciales.
                                </p>
                                <p>
                                    Trabajaremos con usted para refinar y mejorar el diseño a través de múltiples iteraciones sin costo adicional. Esto incluye explorar direcciones creativas alternativas y realizar ajustes basados en tus comentarios. Si bien nuestro objetivo es la satisfacción completa, típicamente consideramos que de 2 a 5 rondas de revisiones son razonables, lo que nos permite mantener los cronogramas del proyecto mientras aseguramos que tu visión se logre.
                                </p>
                                <p>Todos los depósitos y tarifas cobradas de forma anticipada, así como los pagos progresivos realizados durante el curso de nuestros servicios, no son reembolsables. Esto nos ayuda a gestionar los recursos y el tiempo asignados a su proyecto.</p>
                                <p>Los reembolsos también pueden emitirse a discreción de VPixel CO en casos donde no logremos entregar el servicio descrito. Si se ha aprobado algún trabajo de diseño antes de la construcción del sitio web, no se aceptarán cancelaciones y todas las tarifas asociadas (excluyendo el saldo de la factura final) deben pagarse en su totalidad. El saldo de la factura final, que representa un porcentaje del costo total según se especifica en la cotización inicial, se debe al completar el servicio. Este saldo también es no reembolsable.</p>
                                <p>Entendemos que cada negocio tiene necesidades únicas, y aunque pueden surgir limitaciones de software (como con WordPress, WP eCommerce, ShopPlugin o Prestashop), estas no justifican retener los pagos finales. Si necesita una funcionalidad específica que va más allá del acuerdo original, VPixel CO ofrece servicios adicionales para satisfacer sus necesidades, que pueden organizarse por una tarifa extra.</p>
                                <p>Si su sitio web o servicio se interrumpe debido a una cancelación o vencimiento, ofrecemos la opción de restablecerlo dentro de un período de 12 meses por una tarifa de reconexión de $20 USD. Para cualquier servicio recurrente, como alojamiento o mantenimiento, las cancelaciones deben comunicarse con al menos 30 días de anticipación. Los pagos realizados por adelantado para estos servicios no son reembolsables. Las cancelaciones de servicio deben solicitarse formalmente a través de VPixel CO, ya que no procesamos cancelaciones sin previo aviso.</p>
                                <p>En caso de que se apruebe un reembolso, el tiempo de procesamiento puede variar según su banco. Típicamente, los reembolsos se reflejarán en su cuenta dentro de 2 a 5 días hábiles. Sin embargo, los clientes que tengan cuentas en instituciones distintas a Bancolombia pueden experimentar tiempos de procesamiento más largos.</p>

                                <h3 className="h3-title color-yellow" id="h.x7k9p4n2m5vy">Acerca del Servicio de Retainer Mensual de VPixel CO:</h3>
                                <p>El Servicio de Retainer Mensual de VPixel CO (“Servicio”) es una suscripción integral que proporciona un conjunto completo de servicios digitales de manera mensual para abordar las necesidades dinámicas de nuestros clientes. El servicio incluye diseño UI/UX, redacción profesional, estrategia de SEO y marketing, actualizaciones de contenido, mantenimiento de sitios web y más, diseñados para apoyar a los clientes en la gestión y mejora de su presencia en línea.</p>
                                <p>El Cliente es responsable de cubrir todos los costos asociados con cualquier campaña publicitaria, promoción en redes sociales y otras iniciativas de marketing dirigidas a atraer tráfico a su sitio web. Esto incluye, pero no se limita a, anuncios pagados, contenido patrocinado y herramientas o servicios de marketing de terceros fuera del alcance de este acuerdo.</p>
                                <p>La tarifa del retainer se factura mensualmente, comenzando en la fecha de inscripción y se renovará automáticamente a menos que cualquiera de las partes decida cancelar. Todos los honorarios deben pagarse en su totalidad antes de utilizar las horas de servicio cada mes. Esta tarifa cubre hasta 48 horas de trabajo dedicado por mes. Si el cliente requiere trabajo más allá de este límite mensual, pueden aplicarse cargos adicionales o ser necesario un acuerdo separado para acomodar las horas adicionales.</p>
                                <p>Bajo los términos de este servicio, VPixel CO proporcionará actualizaciones continuas, revisiones, mantenimiento y creación de contenido para los sitios web del cliente. Un Gerente de Proyecto dedicado supervisará el alcance del proyecto, asegurando que los entregables cumplan con las expectativas del cliente y gestionando la coordinación y priorización de las tareas según las necesidades del cliente. Todas las solicitudes de actualizaciones o revisiones se atenderán de acuerdo con la disponibilidad y se discutirán cronogramas razonables para cada tarea.</p>
                                <p>Los servicios “ilimitados” ofrecidos dentro de este retainer, incluidas las actualizaciones y el mantenimiento de sitios web, están limitados por la asignación mensual de horas. VPixel CO se reserva el derecho de priorizar las tareas en función del alcance del proyecto, la urgencia y la disponibilidad para maximizar el valor del tiempo asignado cada mes.</p>
                                <p>VPixel CO ofrece soporte prioritario 24/7 para asuntos urgentes. Las consultas generales y actualizaciones recibirán respuesta durante el horario laboral estándar, y la comunicación se facilitará a través de una combinación de Slack, Loom, métodos asincrónicos y reuniones programadas, según lo acordado con el cliente.</p>
                                <p>Cualquiera de las partes puede finalizar el servicio en cualquier momento, y todo el trabajo concluirá el último día del período de facturación actual. No se ofrecen reembolsos por meses parciales o horas no utilizadas; sin embargo, el retainer puede ser pausado y reinstaurado a solicitud del cliente.</p>
                                <p>VPixel CO se compromete a mantener la confidencialidad de toda la información proporcionada por el cliente, incluida la información sensible, y a respetar los más altos estándares de privacidad y protección de datos.</p>
                                <h3 className="h3-title color-yellow" id="h.lefwvelwr5ey">Diseño, Desarrollo y Soporte de Sitios Web:</h3>
                                <p>Al recibir el brief de diseño, se proporcionará al Cliente un mockup del diseño del sitio web a través de un enlace de FIGMA. El Cliente tiene derecho a solicitar un número razonable de cambios en los conceptos de diseño hasta que se logre la satisfacción, considerándose normal entre 2 y 5 revisiones aproximadamente.</p>
                                <p>El sitio web, incluyendo todos los gráficos y el código de programación, sigue siendo propiedad de VPixel CO hasta que se hayan realizado todos los pagos pendientes en su totalidad. Si el Cliente ha comprado un diseño de logo a VPixel CO como parte del paquete del sitio web, el logo debe ser aprobado antes de comenzar el trabajo en el diseño del sitio web.</p>
                                <p>VPixel CO construye sitios web de WordPress para ser autogestionados, lo que significa que no somos responsables de editar, actualizar o mantener el sitio web del Cliente a menos que se acuerde lo contrario en un contrato separado.</p>
                                <p>Todos los sitios web y landing pages se entregarán como plantillas con texto e imágenes de marcador de posición o contenido previamente proporcionado. El Cliente es el único responsable de insertar el texto final, productos e imágenes. Aunque el Cliente retendrá la propiedad total del contenido y diseño del sitio web una vez que se haya pagado el saldo final, ciertos elementos de diseño siguen siendo propiedad intelectual de VPixel CO.</p>
                                <p>Recomendamos encarecidamente al Cliente que guarde de forma segura los activos finales del sitio web y realice copias de seguridad regulares. VPixel CO no es responsable de mantener o almacenar estos entregables indefinidamente, y es aconsejable que el Cliente se asegure de tener acceso a todos los archivos necesarios.</p>
                                <p>Para ayudar con la gestión del sitio web, VPixel CO puede proporcionar un manual (PDF o video grabado) para orientar sobre cómo actualizar y mantener el sitio web. Además, se incluyen dos horas de capacitación en WordPress CMS con cada cotización de diseño de sitio web. Las sesiones de capacitación adicionales están disponibles a una tarifa de $10 USD por hora.</p>
                                <p>Para los clientes que han adquirido servicios de alojamiento a través de VPixel CO, a cada cuenta de sitio web se le asignará una cantidad específica de ancho de banda y espacio en disco. Cualquier uso que exceda la cantidad asignada incurrirá en cargos adicionales. VPixel CO monitorea el uso de ancho de banda y espacio en disco y, si es necesario, aumentará automáticamente la asignación para garantizar un funcionamiento sin problemas. Consulte nuestro sitio web para obtener detalles sobre asignaciones y precios. Aunque monitoreamos el uso, VPixel CO no se hace responsable si un sitio web se desconecta debido a que excede los límites de ancho de banda o espacio en disco. Sin embargo, trabajaremos para restaurar el sitio web a su estado de funcionamiento anterior, siempre que el Cliente actualice o renueve su servicio de alojamiento.</p>
                                <p>Todos los sitios web creados por VPixel CO incluyen una etiqueta de autoría incorporada en el pie de página, con un enlace a nuestro sitio web. Este enlace debe permanecer en su lugar durante todo el período del contrato con VPixel CO.</p>
                                <h3 className="h3-title color-yellow" id="">Garantía:</h3>
                                <p>En VPixel CO, ofrecemos una garantía de 90 días a partir de la fecha de finalización de su sitio web. Durante este período, cualquier error o defecto que se nos informe será reparado sin costo adicional. Una vez que el período de garantía de 90 días haya expirado, VPixel CO ya no será responsable de abordar errores o defectos a menos que se especifique lo contrario en los términos de su acuerdo de alojamiento de sitio web. Cualquier cambio, corrección o error reportado fuera de este período de garantía puede estar sujeto a cargos adicionales.</p>
                                <p>Para problemas relacionados con el alojamiento, proporcionamos una garantía del fabricante por 12 meses desde la fecha en que su sitio web se pone en línea. Esta garantía cubre problemas relacionados con actualizaciones del servidor, reinicios y parches. VPixel CO evaluará si un problema constituye un error relacionado con el alojamiento bajo esta garantía y lo abordará en consecuencia.</p>
                                <p>Nos comprometemos a resolver todos los problemas que se nos informen dentro de las 48 horas posteriores a la notificación, o tras la aceptación de una cotización para las correcciones aplicables.</p>
                                <p>Nuestros servicios se extienden a navegadores web y clientes de correo electrónico lanzados dentro de los 12 meses anteriores a la fecha de finalización de su sitio web o boletín de correo electrónico. Si se requiere compatibilidad con navegadores o clientes más antiguos, pueden aplicarse cargos adicionales.</p>
                                <p>Tenga en cuenta que no somos responsables de los boletines de correo electrónico o correos electrónicos basados en HTML que no se muestren correctamente debido a limitaciones en el cliente de correo electrónico, programa o aplicación que se esté utilizando, particularmente si no admiten los estándares actuales de la industria para el desarrollo de correo electrónico.</p>
                                <p>Además, VPixel CO no es responsable de errores o problemas que surjan si el sitio web se mueve del servidor originalmente instalado sin notificación previa a nosotros, a menos que dichos problemas estén cubiertos por una garantía previamente acordada.</p>
                                <h3 className="h3-title color-yellow" id="afpes">Arreglos Financieros para Proyectos:</h3>
                                <p>El costo del Sitio Web de VPixel CO (incluyendo Sitios Web de Folletos, Sitios Web de Carrito de Compras y Landing Pages) es un precio fijo para diseño e instalación. VPixel CO garantiza que no habrá tarifas continuas a menos que se acuerde lo contrario en un contrato separado.</p>
                                <p>Las estimaciones cotizadas son válidas por 30 días a partir de la fecha de emisión. Nuestro calendario de pagos es el siguiente:</p>
                                <ul>
                                    <li>50% del costo total del proyecto como depósito antes de que comience el trabajo.</li>
                                    <li>El 50% restante del costo total se facturará ya sea 20 días calendario después del pago inicial o al completar y probar el proyecto, marcando el inicio del período de garantía de 90 días, lo que ocurra primero.</li>
                                </ul>
                                <p>Si el cliente lo solicita o el costo del proyecto es inferior a $500 USD, podemos proporcionar un plan de pago en el que tenemos el 50% como depósito y el 50% restante se debe al completar y probar el proyecto, marcando el inicio del período de garantía de 90 días.</p>
                                <p>Si hay variaciones o extensiones al proyecto, o si el Cliente desea contratar a VPixel CO por tiempo y materiales, todo el trabajo se cobrará en una nueva cotización que se enviará al Cliente y será aprobada por ellos.</p>
                                <h3 className="h3-title color-yellow" id="h.n8njn2tt0psa">Diseño Gráfico:</h3>
                                <p>El depósito para el trabajo de diseño gráfico se debe al aceptar la cotización. El saldo restante se debe cuando el arte ha sido aprobado, antes de la entrega final de los archivos gráficos.</p>
                                <p>Cualquier cambio adicional solicitado después de la aprobación será cotizado y facturado por separado.</p>
                                <p>Para servicios de diseño de logotipos, los Clientes recibirán múltiples conceptos de diseño por correo electrónico basados en el brief de diseño. Los Clientes pueden solicitar un número razonable de cambios a sus conceptos elegidos hasta que estén satisfechos con el diseño. Aproximadamente 2-5 rondas de cambios se consideran normales.</p>
                                <p>Tras la aprobación final del diseño, el Cliente recibirá los archivos finales de diseño gráfico por correo electrónico u otros métodos de entrega acordados.</p>
                                <p>VPixel CO proporciona todo el arte en archivos listos para imprimir para el uso del Cliente. Es responsabilidad del Cliente verificar todo el contenido, detalles de contacto, direcciones de correo electrónico, números de teléfono o cualquier otra información solicitada para su precisión. VPixel CO no será legalmente responsable ni de ninguna otra manera por errores en el arte que no se hayan notado antes de su uso, incluyendo errores de ortografía, gramática, problemas de color o errores de tipeo. Recomendamos obtener una "Prueba Química" de su impresor antes de finalizar la impresión.</p>
                                <p>Todos los diseños gráficos creados por VPixel CO son propiedad intelectual del Cliente. La propiedad se transferirá al Cliente al recibir el pago completo.</p>
                                <h3 className="h3-title color-yellow" id="h.uf0cvd8z6zot">Registro de Dominio:</h3>
                                <p>VPixel CO no garantiza que el(los) nombre(s) de dominio solicitado(s) estén disponibles o puedan registrarse bajo el sufijo solicitado. No somos responsables de ningún requisito de nombre de dominio, incluyendo registro, renovación y transferencia.</p>
                                <h3 className="h3-title color-yellow" id="h.i51dvvuq4moc">Servicios de Alojamiento:</h3>
                                <p>VPixel CO aloja expresamente código escrito solo por desarrolladores de VPixel CO (pasados o presentes).</p>
                                <p>Garantizamos la salud de los Sitios Web de WordPress construidos por VPixel CO por un período de un año mientras estén alojados con nosotros, cubriendo solo actualizaciones de navegador y problemas de estilo. Queda a nuestra discreción determinar qué problemas están cubiertos por esta garantía. Las correcciones para estos problemas se proporcionan sin cargo. Cualquier corrección o cambio fuera de estas estipulaciones es facturable.</p>
                                <p>Las facturas por Servicios de Alojamiento Anual se facturan con un mes de anticipación y deben pagarse dentro de los 30 días posteriores a la emisión. El incumplimiento del pago del saldo puede resultar en una interrupción del servicio.</p>
                                <p>No asumimos ninguna responsabilidad por líneas de tiempo, eliminación, entrega errónea o falla en el almacenamiento de sus comunicaciones, configuraciones de personalización o cualquier otra información almacenada en su servicio de alojamiento.</p>
                                <p>VPixel CO no es responsable de ningún contenido vinculado o incrustado de una fuente externa que pueda desaparecer debido a una interrupción de la conexión del servidor. Además, nos reservamos el derecho de eliminar cualquier contenido vinculado o incrustado que infrinja los derechos de autor u otras leyes sin previo aviso al Cliente.</p>
                                <p>En caso de compromiso del servidor, nos esforzaremos por restaurar el sitio web del Cliente a su estado anterior lo antes posible, pero no somos responsables del contenido perdido durante la restauración.</p>
                                <p>No somos responsables de compromisos debido a software de WordPress desactualizado, plugins o software de navegador. Si ocurre un compromiso debido a software de WordPress y/o plugins desactualizados, se facturará al Cliente por actualizar la instalación del sitio web.</p>
                                <p>El rechazo de actualizaciones de WordPress y plugins anula todas las garantías asociadas con Digital Developments.</p>
                                <p>Los Clientes no están obligados contractualmente a permanecer alojados con VPixel CO, y los servicios pueden terminarse en cualquier momento. Las facturas pagadas por adelantado no son reembolsables, y todas las facturas pendientes deben pagarse en su totalidad.</p>
                                <p>Se puede comprar capacidad y ancho de banda adicionales por una tarifa preestablecida y se aplican hasta la próxima factura de renovación o hasta que haya pasado un mes, lo que ocurra primero.</p>
                                <p>VPixel CO se reserva el derecho de cambiar los precios de los paquetes de alojamiento sin previo aviso. Los servicios de alojamiento existentes no se verán afectados por ningún cambio de precios a menos que se notifique lo contrario al Cliente.</p>
                                <p>Si el Cliente solicita que su sitio web se coloque en otro servidor, se puede incurrir en una Tarifa de Configuración basada en el tipo de servidor, ubicación y complejidad del sitio web.</p>
                                <p>Usted es responsable de mantener una copia de cualquier sitio web existente que podamos reemplazar de acuerdo con los servicios de diseño web, incluyendo todas las bases de datos y archivos alojados. Podemos proporcionar una solución de archivo para el sitio web existente a nuestras tarifas comerciales estándar.</p>
                                <h3 className="h3-title color-yellow" id="h.ys5mg0cyelfr">Transferencia:</h3>
                                <p>Este acuerdo no puede ser transferido bajo ninguna circunstancia sin el consentimiento por escrito de VPixel CO.</p>
                                <p>Los sitios web de VPixel CO pueden ser transferidos fuera de nuestros servidores en cualquier momento, pero todas las garantías quedan anuladas y ya no seremos responsables del sitio web o su contenido. Los nombres de dominio siempre seguirán siendo propiedad del Cliente y están registrados bajo el Cliente que los solicitó siempre que sea posible.</p>
                                <p>Este Acuerdo de Términos establece el entendimiento absoluto de todas las partes involucradas. Al renovar los servicios cubiertos por este Acuerdo, cualquier cambio o modificación realizada durante el término del servicio es acordada por ambas partes.</p>
                                <p>Este Acuerdo constituye el entendimiento completo de las partes. Cualquier cambio o modificación a este Contrato es acordado por ambas partes al renovar los servicios.</p>
                                <h3 className="h3-title color-yellow" id="h.fmpb6oq3y2si">Ofertas y Promociones:</h3>
                                <h4 id="h.1pv14yn703cr">Landing Page, aumente sus ventas en tan solo 7 días (https://vpixel.co/landing)</h4>
                                <p>Cupos:</p>
                                <p>Tenemos 5 cupos disponibles para esta promoción. La disponibilidad de cupos disminuirá a medida que los clientes soliciten y paguen por sus respectivos servicios. Si los cupos se agotan en el momento de su solicitud y pago, ajustaremos los recursos necesarios para acomodar su proyecto.</p>
                                <p>Tiempos de Entrega:</p>
                                <p>El tiempo de entrega del producto ofrecido es entre 5 y 12 días calendario para garantizar un trabajo de calidad y eficiencia. El cliente debe proporcionar materiales como textos e imágenes a tiempo para cumplir con este plazo. Si estos materiales no están disponibles, ofrecemos la opción de usar Inteligencia Artificial (IA) para generarlos, pero se requiere material inicial como modelo.</p>
                                <p>Los materiales deben enviarse a VPixel CO exclusivamente por correo electrónico; no se aceptarán materiales a través de otros medios como WhatsApp.</p>
                                <p>No se aceptarán cambios o modificaciones adicionales una vez que la Landing Page esté a punto de ser entregada. Las modificaciones se realizarán después de su publicación.</p>
                                <p>El cliente debe aprobar o proporcionar retroalimentación dentro de un plazo razonable para permitir los ajustes necesarios y mantener el proyecto dentro del plazo estimado.</p>
                                <p>Pagos y Reembolsos:</p>
                                <p>El pago de esta oferta debe realizarse por adelantado al inicio del trabajo, debido a la naturaleza promocional del precio ofrecido.</p>
                                <p>En caso de desacuerdo con el pago por adelantado, están disponibles las siguientes alternativas:</p>
                                <ol>
                                    <li>El pago puede realizarse una vez que VPixel CO haya completado el desarrollo de la Landing Page y esté lista para su publicación, siempre que no hayan pasado más de 10 días desde el inicio del trabajo.</li>
                                    <li>El pago también puede realizarse después de 10 días calendario desde el inicio del trabajo, ya sea que la Landing Page esté completada o no, para evitar la extensión indefinida del proyecto.</li>
                                </ol>
                                <p>Para solicitar reembolsos, por favor envíe un correo electrónico a e@vpixel.co con las razones correspondientes. Los reembolsos se procesarán dentro de dos días hábiles.</p>
                                <h3 className="h3-title color-yellow" id="h.zap3n93vjoan">Impuestos:</h3>
                                <p>VPixel CO no será responsable de ningún impuesto u otras tarifas relacionadas con las ventas realizadas por el Cliente utilizando nuestro servidor. El Cliente acepta asumir la responsabilidad total de todos los impuestos y tarifas asociados con dichos productos vendidos por ellos.</p>
                                <h3 className="h3-title color-yellow" id="h.irqtv8bkxbmj">Hardware, Equipo y Software:</h3>
                                <p>Se asume que el Cliente tiene el conocimiento necesario para mantener el sitio web WordPress de VPixel CO. El Cliente acepta que no es responsabilidad de VPixel CO proporcionar este conocimiento o mantener el sitio web en nombre del Cliente sin la compra de servicios adicionales y el soporte previamente definido.</p>
                                <p>VPixel CO no ofrece garantías, aseguraciones o avales con respecto a la compatibilidad del equipo informático y/o software del Cliente con cualquier producto o servicio de VPixel CO.</p>
                                <p>Es responsabilidad exclusiva del Cliente proporcionar todos los detalles personales o de la empresa necesarios para acceder a los sitios web de VPixel CO.</p>
                                <h3 className="h3-title color-yellow" id="h.4sneltfmjq8j">Limitaciones de Responsabilidad:</h3>
                                <p>VPixel CO no es responsable ante el Cliente (ya sea bajo contrato o de otra manera) bajo las siguientes estipulaciones:</p>
                                <ul>
                                    <li>No hay garantía expresa de ninguna parte, afiliado, agente, comerciante o licenciante, etc., de que cualquier servicio o producto de VPixel CO será ininterrumpido o libre de errores.</li>
                                    <li>No hay garantía expresa con respecto a la precisión, confiabilidad o contenido de cualquier información o servicio contenido o proporcionado a través de los servidores de VPixel CO (incluyendo sitios web) a menos que se indique expresamente lo contrario en este acuerdo.</li>
                                    <li>VPixel CO no es responsable de ningún daño a los sistemas informáticos del Cliente o pérdida de datos resultante de la descarga, uso o distribución de cualquier material - digital o de otro tipo - de nuestros sitios web o cualquier afiliado o cliente activo.</li>
                                    <li>VPixel CO, incluyendo sus funcionarios, agentes, empleados, etc., no es responsable (incluso por negligencia) de ningún daño directo, indirecto, incidental, especial o consecuente resultante del uso o incapacidad de usar nuestros servicios, o de errores, omisiones, interrupciones, eliminación de archivos, errores, defectos, retrasos en la operación o transmisión, o cualquier falla de rendimiento, ya sea o no limitado a actos de Dios, falla de comunicación, robo, destrucción o acceso no autorizado a nuestros registros, programas, servidores o servicios.</li>
                                    <li>Independientemente de lo anterior, los recursos exclusivos del Cliente para todos los daños, pérdidas y causas de acciones, ya sea por contrato, agravio (incluyendo negligencia) o de otra manera, no excederán la cantidad combinada en dólares pagada por el Cliente durante el término de este Acuerdo, más honorarios razonables de abogados y costos judiciales.</li>
                                </ul>
                                <h3 className="h3-title color-yellow" id="h.vsqpbnvoq41n">Descargo de Responsabilidad:</h3>
                                <p>VPixel CO y sus matrices, subsidiarias, afiliados, funcionarios, directores, accionistas, empleados y agentes no serán responsables, bajo ninguna circunstancia o teoría legal, por:</p>
                                <ul>
                                    <li>Cualquier pérdida de negocios, ganancias o buena voluntad, pérdida de uso o datos, interrupción del negocio, o cualquier daño indirecto, especial, incidental o consecuente de cualquier carácter, incluso si VPixel CO es consciente del riesgo de tales daños, resultantes de cualquier manera del uso o incapacidad del Cliente para usar los servicios en línea o software, o resultantes de errores, defectos, omisiones, retrasos en la operación o transmisión, o cualquier otra falla de rendimiento del sitio web, servidores o software.</li>
                                    <li>VPixel CO renuncia a cualquier garantía de comerciabilidad o idoneidad para un propósito particular y no será responsable de ningún daño sufrido por el Cliente, incluyendo pérdida de datos resultante de retrasos, no entregas o interrupciones del servicio por cualquier causa o errores u omisiones del Cliente.</li>
                                    <li>VPixel CO limita expresamente sus daños al Cliente por cualquier tiempo de no accesibilidad u otro tiempo de inactividad al cargo mensual o anual prorrateado durante la indisponibilidad del servicio. VPixel CO niega específicamente cualquier responsabilidad por daños que surjan como consecuencia de dicha indisponibilidad.</li>
                                    <li>El uso de cualquier información obtenida a través de VPixel CO es bajo el propio riesgo del Cliente, y VPixel CO niega específicamente cualquier responsabilidad por la precisión o calidad de la información obtenida a través de nuestros servicios.</li>
                                    <li>VPixel CO no puede ser considerado responsable ni controlar las acciones de los usuarios del sitio web, visitantes o terceros vinculados.</li>
                                </ul>
                                <h3 className="h3-title color-yellow" id="h.ptl9fkr9rerb">Buena Voluntad:</h3>
                                <p>Esta propuesta asume buena voluntad de ambas partes con respecto a:</p>
                                <ul>
                                    <li>Expectativas razonables para logros dentro de un plazo determinado.</li>
                                    <li>Utilización efectiva de recursos para lograr resultados óptimos.</li>
                                </ul>
                                <h3 className="h3-title color-yellow" id="h.vbys59478gin">Marcas Comerciales y Derechos de Autor:</h3>
                                <p>El Cliente garantiza el derecho a utilizar cualquier marca comercial aplicable.</p>
                                <h3 className="h3-title color-yellow" id="h.sbtay2jk1dtj">Indemnización:</h3>
                                <p>El Cliente acepta defender, indemnizar y mantener a VPixel CO libre de cualquier demanda, responsabilidad, pérdida, costo y reclamo, incluyendo honorarios razonables de abogados, afirmados contra VPixel CO, sus agentes, clientes, sirvientes, funcionarios y empleados, que surjan de:</p>
                                <ul>
                                    <li>Cualquier servicio proporcionado o realizado, o acordado a ser realizado, por el Cliente, sus agentes, empleados o asignados.</li>
                                    <li>Lesiones a personas o daños a la propiedad causados por productos vendidos o distribuidos en conexión con el servidor de VPixel CO.</li>
                                    <li>Material suministrado por el Cliente que infrinja los derechos de propiedad de un tercero.</li>
                                    <li>Infracción de derechos de autor.</li>
                                    <li>Productos defectuosos vendidos en el servidor de VPixel CO.</li>
                                </ul>
                                <h3 className="h3-title color-yellow" id="h.yynhkhqsct24">Leyes Aplicables y Divisibilidad:</h3>
                                <p>Este acuerdo se regirá por las leyes de Bogotá, Colombia. Todas las partes se someten irrevocablemente a la jurisdicción no exclusiva de los tribunales de Colombia.</p>
                                <h3 className="h3-title color-yellow" id="h.vm5k4rtqkvds">Revisiones del Contrato:</h3>
                                <p>Las revisiones de este Contrato serán aplicables a los Contratos anteriores. Se considerará que el Cliente ha aceptado las revisiones al renovar los servicios de VPixel CO, según lo especificado en la Sección 2: Cancelación y Reembolso.</p>


                            </div >


                        </div >
                    </div >
                </div >
            </div >
        </>
    )
}

export default Terms;
