import React, { useEffect, useRef } from 'react';

const Compare = () => {
    const sliderRef = useRef(null);
    const rangeRef = useRef(null);
    const thumbRef = useRef(null);
    const overlayRef = useRef(null);

    useEffect(() => {
        const slider = sliderRef.current;
        const range = rangeRef.current;

        const setSliderState = (e) => {
            if (e.type === 'input') {
                range.classList.add('image-comparison__range--active');
            } else {
                range.classList.remove('image-comparison__range--active');
                slider.removeEventListener('mousemove', moveSliderThumb);
            }
        };

        const moveSliderThumb = (e) => {
            const thumb = thumbRef.current;
            let position = e.clientY - slider.getBoundingClientRect().top - 20;
            position = Math.max(-20, Math.min(position, slider.offsetHeight - 20));
            thumb.style.top = `${position}px`;
        };

        const moveSliderRange = (e) => {
            const value = e.target.value;
            const sliderElement = slider.querySelector('[data-image-comparison-slider]');
            const overlay = overlayRef.current;

            sliderElement.style.left = `${value}%`;
            overlay.style.width = `${value}%`;

            slider.addEventListener('mousemove', moveSliderThumb);
            setSliderState(e);
        };

        if ('ontouchstart' in window === false) {
            range.addEventListener('mouseup', setSliderState);
            range.addEventListener('mousedown', moveSliderThumb);
        }

        range.addEventListener('input', moveSliderRange);
        range.addEventListener('change', moveSliderRange);

        return () => {
            if ('ontouchstart' in window === false) {
                range.removeEventListener('mouseup', setSliderState);
                range.removeEventListener('mousedown', moveSliderThumb);
            }
            range.removeEventListener('input', moveSliderRange);
            range.removeEventListener('change', moveSliderRange);
        };
    }, []);

    return (
        <div className="image-comparison" ref={sliderRef} data-component="image-comparison-slider">
            <div className="image-comparison__slider-wrapper">
                <label htmlFor="image-comparison-range" className="image-comparison__label">Move image comparison slider</label>
                <input
                    ref={rangeRef}
                    type="range"
                    min="0"
                    max="100"
                    defaultValue="50"
                    className="image-comparison__range"
                    id="image-compare-range"
                    data-image-comparison-range=""
                />

                <div ref={overlayRef} className="image-comparison__image-wrapper image-comparison__image-wrapper--overlay" data-image-comparison-overlay="">
                    <figure className="image-comparison__figure image-comparison__figure--overlay">
                        <picture className="image-comparison__picture">
                            <img src="/images/landing/old-2.webp" alt="ECM Marine Tuning Website" className="image-comparison__image" />
                        </picture>

                        <figcaption className="image-comparison__caption image-comparison__caption--before">
                            <span className="image-comparison__caption-body">Antes</span>
                        </figcaption>
                    </figure>
                </div>

                <div className="image-comparison__slider" data-image-comparison-slider="">
                    <span ref={thumbRef} className="image-comparison__thumb" data-image-comparison-thumb="">
                        <svg className="image-comparison__thumb-icon" xmlns="http://www.w3.org/2000/svg" width="18" height="10" viewBox="0 0 18 10" fill="currentColor">
                            <path className="image-comparison__thumb-icon--left" d="M12.121 4.703V.488c0-.302.384-.454.609-.24l4.42 4.214a.33.33 0 0 1 0 .481l-4.42 4.214c-.225.215-.609.063-.609-.24V4.703z"></path>
                            <path className="image-comparison__thumb-icon--right" d="M5.879 4.703V.488c0-.302-.384-.454-.609-.24L.85 4.462a.33.33 0 0 0 0 .481l4.42 4.214c.225.215.609.063.609-.24V4.703z"></path>
                        </svg>
                    </span>
                </div>

                <div className="image-comparison__image-wrapper">
                    <figure className="image-comparison__figure">
                        <picture className="image-comparison__picture">
                            <img src="/images/landing/new-2.webp" alt="ECM Marine Tuning Website" className="image-comparison__image" />
                        </picture>

                        <figcaption className="image-comparison__caption image-comparison__caption--after">
                            <span className="image-comparison__caption-body">Después</span>
                        </figcaption>
                    </figure>
                </div>
            </div>
        </div>
    );
};

export default Compare;