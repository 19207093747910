import React from 'react';
import { useLocation } from 'react-router-dom';

const WebsiteTypeSelector = ({ value, onChange }) => {
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const typeParam = searchParams.get('type');

    const websiteTypes = [
        { id: 'landing', label: 'Landing Page', icon: '🏠' },
        { id: 'marketing', label: 'Marketing Website', icon: '📣' },
        { id: 'catalog', label: 'Virtual Catalog', icon: '📚' },
        { id: 'ecommerce', label: 'E-commerce Site', icon: '🛒' },
    ];

    const hasPreselected = websiteTypes.some(type =>
        (typeParam === type.id && ['landing', 'website', 'ecommerce'].includes(typeParam)) ||
        (typeParam === 'wordpress' && type.id === 'marketing')
    );

    return (
        <div className="website-type-selector">
            {websiteTypes.map((type) => {
                const isPreselected = (typeParam === type.id && ['landing', 'website', 'ecommerce'].includes(typeParam)) ||
                    (typeParam === 'wordpress' && type.id === 'marketing');

                return (
                    <button
                        key={type.id}
                        type="button"
                        className={`website-type-button ${value === type.id ? 'selected' : ''} ${isPreselected ? 'pre-selected' : ''}`}
                        onClick={() => onChange(type.id)}
                        disabled={hasPreselected && !isPreselected}
                    >
                        <span className="website-type-icon">{type.icon}</span>
                        <span className="website-type-label">{type.label}</span>
                    </button>
                );
            })}
        </div>
    );
};

export default WebsiteTypeSelector;
