import React from 'react';

const ProgressBar = ({ currentStage, totalStages }) => {
    const progress = ((currentStage + 1) / totalStages) * 100;

    return (
        <div className="progress-bar-container">



            <span className="progress-text">{Math.round(progress)}% Complete</span>

            <div className="progress-bar-wrapper">
                <div className="progress-bar" style={{ width: `${progress}%` }}>
                </div>
            </div>




        </div>
    );
};

export default ProgressBar;

