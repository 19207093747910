import React from 'react';

const standardLabels = {
    name: "Full Name",
    email: "Email Address",
    confirmEmail: "Confirm Email Address",
    phone: "Phone Number",
    company: "Company Name",
    website: "Current Website URL"
};

const FormStage = ({ stage, formData, onChange, onWebsiteTypeChange, errors }) => {
    return (
        <div className="form-stage">
            <h2>{stage.title}</h2>
            {stage.fields.map((field) => (
                <div key={field.name} className="form-field">
                    <label htmlFor={field.name}>{field.label}</label>
                    {field.type === 'custom' ? (
                        <field.component
                            value={formData[field.name]}
                            onChange={(value) => onWebsiteTypeChange(value)}
                        />
                    ) : field.type === 'checkbox' ? (
                        <div className="checkbox-group">
                            {field.options.map((option) => (
                                <label key={option} className="checkbox-label">
                                    <input
                                        type="checkbox"
                                        name={field.name}
                                        value={option}
                                        checked={(formData[field.name] || []).includes(option)}
                                        onChange={onChange}
                                    />
                                    {option}
                                </label>
                            ))}
                        </div>
                    ) : field.type === 'select' ? (
                        <select
                            id={field.name}
                            name={field.name}
                            value={formData[field.name] || ''}
                            onChange={onChange}
                        >
                            <option value="">Select an option</option>
                            {field.options.map((option) => (
                                <option key={option} value={option}>
                                    {option}
                                </option>
                            ))}
                        </select>
                    ) : field.type === 'textarea' ? (
                        <textarea
                            id={field.name}
                            name={field.name}
                            value={formData[field.name] || ''}
                            onChange={onChange}
                            placeholder={field.placeholder}
                        />
                    ) : (
                        <input
                            type={field.type}
                            id={field.name}
                            name={field.name}
                            value={formData[field.name] || ''}
                            onChange={onChange}
                            placeholder={field.placeholder}
                        />
                    )}
                    {errors && errors[field.name] && (
                        <div className="error-message">{errors[field.name]}</div>
                    )}
                </div>
            ))}
        </div>
    );
};

export default FormStage;

